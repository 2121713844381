// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@charset "UTF-8";
.ShopPage_shopPage__Ez2tr {
  /* min-height: 100vh; Удаляем это свойство */
  display: flex;
  flex-direction: column;
  flex: 1 1; /* Добавляем flex: 1 для заполнения доступного пространства */
}

.ShopPage_scrollContainer__vIaf7 {
  flex: 1 1;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  /* Если padding-bottom не нужен, можно его убрать */
}

.ShopPage_shop_title__9XjBh {
  font-size: 24px;
  font-weight: 600;
  text-align: center;
  color: #f1f1f1;
  margin-bottom: 10px;
}

.ShopPage_tabNavigation__aobKF {
  display: flex;
  justify-content: space-between;
  padding: 5px 8px;
  background: #2d3236;
  height: 50px;
  border-radius: 10px;
}

.ShopPage_tabContent__qIvLn {
  padding-top: 15px;
}

.ShopPage_tabButton__8FESH {
  flex: 1 1;
  padding: 8px 16px;
  background-color: transparent;
  border: none;
  color: #f1f1f1;
  font-size: 13px;
  font-weight: 700;
  line-height: 21px;
  text-align: center;
  border-radius: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}
.ShopPage_tabButton__8FESH.ShopPage_active__NzxRT {
  background-color: #1f1f1f;
}
.ShopPage_tabButton__8FESH:not(.ShopPage_active__NzxRT):hover {
  background-color: #3e3e3e;
}`, "",{"version":3,"sources":["webpack://./src/pages/ShopPage/ShopPage.module.scss"],"names":[],"mappings":"AAAA,gBAAgB;AAAhB;EACE,4CAAA;EACA,aAAA;EACA,sBAAA;EACA,SAAA,EAAA,6DAAA;AAEF;;AACA;EACE,SAAA;EACA,gBAAA;EACA,iCAAA;EACA,mDAAA;AAEF;;AACA;EACE,eAAA;EACA,gBAAA;EACA,kBAAA;EACA,cAAA;EACA,mBAAA;AAEF;;AACA;EACE,aAAA;EACA,8BAAA;EACA,gBAAA;EACA,mBAAA;EACA,YAAA;EACA,mBAAA;AAEF;;AACA;EACE,iBAAA;AAEF;;AACA;EACE,SAAA;EACA,iBAAA;EACA,6BAAA;EACA,YAAA;EACA,cAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,kBAAA;EACA,mBAAA;EACA,eAAA;EACA,sCAAA;AAEF;AAAE;EACE,yBAAA;AAEJ;AACE;EACE,yBAAA;AACJ","sourcesContent":[".shopPage {\n  /* min-height: 100vh; Удаляем это свойство */\n  display: flex;\n  flex-direction: column;\n  flex: 1; /* Добавляем flex: 1 для заполнения доступного пространства */\n}\n\n.scrollContainer {\n  flex: 1;\n  overflow-y: auto;\n  -webkit-overflow-scrolling: touch;\n  /* Если padding-bottom не нужен, можно его убрать */\n}\n\n.shop_title {\n  font-size: 24px;\n  font-weight: 600;\n  text-align: center;\n  color: #f1f1f1;\n  margin-bottom: 10px;\n}\n\n.tabNavigation {\n  display: flex;\n  justify-content: space-between;\n  padding: 5px 8px;\n  background: #2d3236;\n  height: 50px;\n  border-radius: 10px;\n}\n\n.tabContent {\n  padding-top: 15px;\n}\n\n.tabButton {\n  flex: 1;\n  padding: 8px 16px;\n  background-color: transparent;\n  border: none;\n  color: #f1f1f1;\n  font-size: 13px;\n  font-weight: 700;\n  line-height: 21px;\n  text-align: center;\n  border-radius: 10px;\n  cursor: pointer;\n  transition: background-color 0.3s ease;\n\n  &.active {\n    background-color: #1f1f1f;\n  }\n\n  &:not(.active):hover {\n    background-color: #3e3e3e;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"shopPage": `ShopPage_shopPage__Ez2tr`,
	"scrollContainer": `ShopPage_scrollContainer__vIaf7`,
	"shop_title": `ShopPage_shop_title__9XjBh`,
	"tabNavigation": `ShopPage_tabNavigation__aobKF`,
	"tabContent": `ShopPage_tabContent__qIvLn`,
	"tabButton": `ShopPage_tabButton__8FESH`,
	"active": `ShopPage_active__NzxRT`
};
export default ___CSS_LOADER_EXPORT___;
