// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@charset "UTF-8";
/* src/components/UI/CoinEffect/CoinEffect.module.scss */
.CoinEffect_coinEffect__UKDu2 {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw; /* Занимает всю ширину окна */
  height: 100vh; /* Занимает всю высоту окна */
  pointer-events: none; /* Не блокирует клики по другим элементам */
  z-index: 2000; /* Поверх других элементов */
  overflow: hidden; /* Скрывает выходящие за границы монеты */
}

.CoinEffect_coin__78CXb {
  position: absolute;
  width: 40px;
  height: 40px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  animation: CoinEffect_flyIcon__LiA3s 0.5s forwards ease-out;
  animation-delay: var(--delay);
  will-change: transform, opacity;
  opacity: 0; /* Начальное состояние прозрачности */
}

@keyframes CoinEffect_flyIcon__LiA3s {
  from {
    opacity: 1;
    transform: translate(-50%, -50%) translate(0, 0) scale(1) rotate(0deg);
  }
  to {
    opacity: 0;
    transform: translate(-50%, -50%) translate(var(--move-x), var(--move-y)) scale(0.5) rotate(720deg);
  }
}`, "",{"version":3,"sources":["webpack://./src/components/UI/CoinEffect/CoinEffect.module.scss"],"names":[],"mappings":"AAAA,gBAAgB;AAAhB,wDAAA;AAEA;EACE,eAAA;EACA,MAAA;EACA,OAAA;EACA,YAAA,EAAA,6BAAA;EACA,aAAA,EAAA,6BAAA;EACA,oBAAA,EAAA,2CAAA;EACA,aAAA,EAAA,4BAAA;EACA,gBAAA,EAAA,yCAAA;AACF;;AAEA;EACE,kBAAA;EACA,WAAA;EACA,YAAA;EACA,SAAA;EACA,QAAA;EACA,gCAAA;EACA,2DAAA;EACA,6BAAA;EACA,+BAAA;EACA,UAAA,EAAA,qCAAA;AACF;;AAEA;EACE;IACE,UAAA;IACA,sEAAA;EACF;EACA;IACE,UAAA;IACA,kGAAA;EACF;AACF","sourcesContent":["/* src/components/UI/CoinEffect/CoinEffect.module.scss */\n\n.coinEffect {\n  position: fixed;\n  top: 0;\n  left: 0;\n  width: 100vw; /* Занимает всю ширину окна */\n  height: 100vh; /* Занимает всю высоту окна */\n  pointer-events: none; /* Не блокирует клики по другим элементам */\n  z-index: 2000; /* Поверх других элементов */\n  overflow: hidden; /* Скрывает выходящие за границы монеты */\n}\n\n.coin {\n  position: absolute;\n  width: 40px;\n  height: 40px;\n  left: 50%;\n  top: 50%;\n  transform: translate(-50%, -50%);\n  animation: flyIcon 0.5s forwards ease-out;\n  animation-delay: var(--delay);\n  will-change: transform, opacity;\n  opacity: 0; /* Начальное состояние прозрачности */\n}\n\n@keyframes flyIcon {\n  from {\n    opacity: 1;\n    transform: translate(-50%, -50%) translate(0, 0) scale(1) rotate(0deg);\n  }\n  to {\n    opacity: 0;\n    transform: translate(-50%, -50%) translate(var(--move-x), var(--move-y))\n      scale(0.5) rotate(720deg);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"coinEffect": `CoinEffect_coinEffect__UKDu2`,
	"coin": `CoinEffect_coin__78CXb`,
	"flyIcon": `CoinEffect_flyIcon__LiA3s`
};
export default ___CSS_LOADER_EXPORT___;
