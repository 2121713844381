// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@charset "UTF-8";
.App {
  text-align: center;
  font-family: Arial, sans-serif;
  background: #272727;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 480px) {
  .App {
    padding: 0 10px;
  }
}
@media (min-width: 1024px) {
  .App {
    padding: 20px;
    max-width: 80vw;
  }
}
/* index.css или App.scss */
html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
  touch-action: none;
}

#root {
  height: 100%;
}

/* index.css или App.scss */
/* index.css или App.scss */
* {
  -webkit-user-select: none; /* Chrome, Safari */ /* Firefox */ /* IE10+ */
  user-select: none; /* Standard */
}

.loading {
  text-align: center;
  color: #fff;
  background-color: #272727;
}`, "",{"version":3,"sources":["webpack://./src/App.scss"],"names":[],"mappings":"AAAA,gBAAgB;AAAhB;EACE,kBAAA;EACA,8BAAA;EACA,mBAAA;EACA,iBAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;AAEF;;AACA;EACE;IACE,eAAA;EAEF;AACF;AACA;EACE;IACE,aAAA;IACA,eAAA;EACF;AACF;AAEA,2BAAA;AAEA;;EAEE,SAAA;EACA,UAAA;EACA,YAAA;EACA,kBAAA;AADF;;AAIA;EACE,YAAA;AADF;;AAIA,2BAAA;AAEA,2BAAA;AAEA;EACE,yBAAA,EAAA,mBAAA,EACA,YAAA,EACA,UAAA;EACA,iBAAA,EAAA,aAAA;AAHF;;AAMA;EACE,kBAAA;EACA,WAAA;EACA,yBAAA;AAHF","sourcesContent":[".App {\n  text-align: center;\n  font-family: Arial, sans-serif;\n  background: #272727;\n  min-height: 100vh;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n\n@media (max-width: 480px) {\n  .App {\n    padding: 0 10px;\n  }\n}\n\n@media (min-width: 1024px) {\n  .App {\n    padding: 20px;\n    max-width: 80vw;\n  }\n}\n\n/* index.css или App.scss */\n\nhtml,\nbody {\n  margin: 0;\n  padding: 0;\n  height: 100%;\n  touch-action: none;\n}\n\n#root {\n  height: 100%;\n}\n\n/* index.css или App.scss */\n\n/* index.css или App.scss */\n\n* {\n  -webkit-user-select: none; /* Chrome, Safari */\n  -moz-user-select: none; /* Firefox */\n  -ms-user-select: none; /* IE10+ */\n  user-select: none; /* Standard */\n}\n\n.loading {\n  text-align: center;\n  color: #fff;\n  background-color: #272727;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
