// src/components/UpgradesShop/UpgradesShop.tsx

import React, { useState, useEffect, useCallback, useMemo } from "react";
import axios, { CancelTokenSource } from "axios";
import useCoinStore from "../../store/useCoinStore";
import useModalStore from "../../store/useModalStore";
import { useUserStore } from "../../store/useUserStore";
import styles from "./UpgradesShop.module.scss";
import UpgradeModalContent from "./UpgradeModalContent/UpgradeModalContent";
import { ReactComponent as CoinIcon } from "../../assets/icons/coin.svg";
import { Upgrade } from "../../types/Upgrade";
import CoinEffect from "../UI/CoinEffect/CoinEffect";
import vibrate from "../../utils/vibrate";

const UpgradesShop: React.FC = React.memo(() => {
  const { openModal, closeModal } = useModalStore();
  const coins = useCoinStore((state) => state.coins);
  const upgrades = useCoinStore((state) => state.upgrades);
  const isPurchasing = useCoinStore((state) => state.isPurchasing);
  const purchaseUpgrade = useCoinStore((state) => state.purchaseUpgrade);
  const setUpgrades = useCoinStore((state) => state.setUpgrades);
  const user = useUserStore((state) => state.user);

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [showCoinEffect, setShowCoinEffect] = useState(false);

  const fetchUpgrades = useCallback(async () => {
    if (!user) {
      console.error("Пользователь не авторизован.");
      setIsLoading(false);
      return;
    }

    const source: CancelTokenSource = axios.CancelToken.source();

    try {
      const response = await axios.get<Upgrade[]>(
        `${process.env.REACT_APP_API_URL}api/upgrades`,
        {
          params: { userId: user.id },
          cancelToken: source.token,
        }
      );
      setUpgrades(response.data);
      console.log("Апгрейды обновлены:", response.data);
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log("Запрос отменен:", error.message);
      } else {
        console.error("Ошибка при получении списка бустеров:", error);
      }
    } finally {
      setIsLoading(false);
    }

    return () => {
      source.cancel("Запрос отменен пользователем.");
    };
  }, [user, setUpgrades]);

  useEffect(() => {
    setIsLoading(true);
    fetchUpgrades();
  }, [fetchUpgrades]);

  const handleUpgradeClick = useCallback(
    (upgrade: Upgrade) => {
      const isAffordable = coins >= (upgrade.next_level_cost || 0);
      const isMaxed = upgrade.next_level === null;

      openModal(
        <UpgradeModalContent
          upgrade={upgrade}
          onPurchase={async () => {
            try {
              await purchaseUpgrade(upgrade.upgrade_id);
              await fetchUpgrades(); // Обновляем список бустеров
              setShowCoinEffect(true);
              vibrate(100);
            } catch (error) {
              console.error("Ошибка при покупке апгрейда:", error);
              // Обработка ошибок, если необходимо
            }
          }}
          isPurchasing={isPurchasing === upgrade.upgrade_id}
          isAffordable={isAffordable}
          isMaxed={isMaxed}
          onClose={() => closeModal()}
        />,
        "linear-gradient(180deg, #2D3236 0%, #000000 100%)"
      );
    },
    [coins, openModal, closeModal, purchaseUpgrade, isPurchasing, fetchUpgrades]
  );

  const renderUpgradeItem = useCallback(
    (upgrade: Upgrade) => (
      <div
        key={upgrade.upgrade_id}
        className={styles.upgradeItem}
        onClick={() => handleUpgradeClick(upgrade)}
      >
        <div className={styles.upgradeImage_wrapper}>
          {upgrade.url && (
            <picture>
              <source
                srcSet={require(`../../assets/images/${upgrade.url.replace(
                  /\.(png|jpg|jpeg|gif)$/,
                  ".webp"
                )}`)}
                type="image/webp"
              />
              <img
                className={styles.upgradeImage}
                src={require(`../../assets/images/${upgrade.url}`)}
                alt={upgrade.name}
              />
            </picture>
          )}
          <div className={styles.gradientOverlay}></div>
        </div>
        <div className={styles.upgradeContent}>
          <div className={styles.topRow}>
            <div className={styles.upgradeName}>{upgrade.name}</div>
            <div className={styles.upgradeLevel}>
              Уровень {upgrade.current_level}
            </div>
          </div>
          <div className={styles.bottomRow}>
            <div className={styles.income}>
              <p className={styles.income__top}>Доход в час:</p>
              <div className={styles.income__value}>
                <CoinIcon className={styles.income__icon} />
                <span className={styles.income__value_text}>
                  +{upgrade.cumulative_income}
                </span>
              </div>
            </div>
            {upgrade.next_level_cost !== null && (
              <div className={styles.currentCostWrapper}>
                <CoinIcon className={styles.another__icon} />
                <span className={styles.currentCost__value}>
                  {upgrade.next_level_cost}
                </span>
              </div>
            )}
          </div>
        </div>
      </div>
    ),
    [handleUpgradeClick]
  );

  const sortedUpgrades = useMemo(() => {
    if (!upgrades) return [];
    return [...upgrades].sort((a, b) => a.upgrade_id - b.upgrade_id);
  }, [upgrades]);

  if (isLoading) {
    return <div className={styles.loading}>Загрузка бустеров...</div>;
  }

  return (
    <div className={styles.upgradesShop}>
      <div className={styles.upgrades}>
        {sortedUpgrades.length === 0 ? (
          <div className={styles.noUpgrades}>Бустеры не найдены.</div>
        ) : (
          sortedUpgrades.map((upgrade) => renderUpgradeItem(upgrade))
        )}
      </div>
      {showCoinEffect && (
        <CoinEffect
          onComplete={() => {
            setShowCoinEffect(false);
          }}
        />
      )}
    </div>
  );
});

export default UpgradesShop;
