// src/utils/vibrate.ts

const vibrate = (pattern: number | number[]) => {
  const isProduction = process.env.NODE_ENV === "production";

  if (isProduction && (window as any)?.tg?.HapticFeedback) {
    (window as any).tg.HapticFeedback.impactOccurred("medium");
  } else if (
    typeof navigator !== "undefined" &&
    typeof navigator.vibrate === "function"
  ) {
    navigator.vibrate(pattern);
  } else {
    console.warn(
      "Vibration API не поддерживается на этом устройстве или в этом браузере."
    );
  }
};

export default vibrate;
