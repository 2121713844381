import React, { useEffect, useRef, useCallback, useState } from "react";
import { Outlet } from "react-router-dom";
import FooterNav from "../FooterNav/FooterNav";
import Header from "../Header/Header";
import useModalStore from "../../store/useModalStore";
import Modal from "../UI/Modal/Modal";
import useCoinStore from "../../store/useCoinStore";
import SharedContainer from "../UI/SharedContainer/SharedContainer";
import { useUserStore } from "../../store/useUserStore";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./Layout.scss";
import axios from "axios";
import OfflineIncomeModal from "../UI/OfflineIncomeModal/OfflineIncomeModal";

const Layout: React.FC = () => {
  const { modalStack, closeModal, openModal } = useModalStore();
  const { user, isLoading } = useUserStore();

  const { setOfflineIncome, initializeStore, offlineIncome } = useCoinStore();

  const hasShownIncomeModal = useRef(false);
  const isFetchingPassiveIncome = useRef(false);

  // Состояние для отслеживания ориентации
  const [isLandscape, setIsLandscape] = useState<boolean>(
    window.matchMedia("(orientation: landscape)").matches
  );

  // Определение режима разработки
  const isDev = process.env.REACT_APP_USE_MOCK_DATA === "true";

  useEffect(() => {
    if (user) {
      initializeStore(user);
    }
  }, [user, initializeStore]);

  const fetchPassiveIncome = useCallback(async () => {
    if (
      !user ||
      hasShownIncomeModal.current ||
      isFetchingPassiveIncome.current
    ) {
      return;
    }

    isFetchingPassiveIncome.current = true;

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}api/passiveIncome`,
        { params: { userId: user.id } }
      );

      const data = response.data;

      if (data.passive_income_earned > 0) {
        setOfflineIncome(
          offlineIncome + Math.floor(data.passive_income_earned)
        );
      }
    } catch (error) {
      console.error("Ошибка при получении пассивного дохода:", error);
    } finally {
      isFetchingPassiveIncome.current = false;
    }
  }, [user, setOfflineIncome, offlineIncome]);

  useEffect(() => {
    if (user) {
      fetchPassiveIncome();
    }
  }, [user, fetchPassiveIncome]);

  useEffect(() => {
    if (offlineIncome > 0 && !hasShownIncomeModal.current) {
      openModal(
        <OfflineIncomeModal
          income={offlineIncome}
          onClose={() => {
            closeModal();
            setOfflineIncome(0);
          }}
        />,
        "linear-gradient(180deg, #2D3236 0%, #000000 100%)"
      );
      hasShownIncomeModal.current = true;
    }
  }, [offlineIncome, closeModal, openModal, setOfflineIncome]);

  // Обработчик изменения ориентации
  useEffect(() => {
    const handleOrientationChange = () => {
      const isNowLandscape = window.matchMedia(
        "(orientation: landscape)"
      ).matches;
      setIsLandscape(isNowLandscape);
    };

    // Добавляем слушатель события
    window.addEventListener("resize", handleOrientationChange);
    window.addEventListener("orientationchange", handleOrientationChange);

    // Проверка текущей ориентации при монтировании
    handleOrientationChange();

    // Убираем слушатель при размонтировании
    return () => {
      window.removeEventListener("resize", handleOrientationChange);
      window.removeEventListener("orientationchange", handleOrientationChange);
    };
  }, []);

  if (isLoading) {
    return <div>Загрузка пользователя...</div>;
  }

  // Условный рендеринг на основе режима и ориентации
  if (!isDev && isLandscape) {
    return (
      <div className="orientation-message">
        <div className="orientation-message-content">
          <p>Но ведь тапать так неудобно...</p>
        </div>
      </div>
    );
  }

  return (
    <div className="layout">
      <Header />
      <div className="content-wrapper">
        <SharedContainer>
          <Outlet />
        </SharedContainer>
      </div>
      <FooterNav />
      {modalStack.map(({ id, content, backgroundColor }) => (
        <Modal
          key={id}
          isOpen
          onClose={() => closeModal(id)}
          backgroundColor={backgroundColor}
        >
          {content}
        </Modal>
      ))}
      <ToastContainer />

      {/* Условный рендеринг сообщения при горизонтальной ориентации в dev режиме */}
      {isDev && isLandscape && (
        <div className="orientation-message">
          <div className="orientation-message-content">
            <p>Так ведь тапать не удобно..</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default React.memo(Layout);
