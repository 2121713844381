// src/utils/haptic.ts

export const triggerHapticFeedback = (
  style: "light" | "medium" | "heavy" | "rigid" | "soft"
) => {
  if (
    window.Telegram &&
    window.Telegram.WebApp &&
    window.Telegram.WebApp.HapticFeedback &&
    typeof window.Telegram.WebApp.HapticFeedback.impactOccurred === "function"
  ) {
    window.Telegram.WebApp.HapticFeedback.impactOccurred(style);
  } else {
    // Фолбэк на стандартную вибрацию, если API недоступен
    if (navigator.vibrate) {
      // В зависимости от стиля можно варьировать длительность вибрации
      const vibrationPattern = {
        light: 30,
        medium: 50,
        heavy: 70,
        rigid: 50,
        soft: 30,
      };
      navigator.vibrate(vibrationPattern[style] || 50);
    }
  }
};
