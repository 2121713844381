import React from "react";
import styles from "./RulesModal.module.scss";
const RulesModal: React.FC = () => {
  return (
    <div className={`${styles.modal_wrapper} ${styles.rulesModal}`}>
      <h2 className={styles.modalTitle}>Правила Игры</h2>
      <div className={styles.modalContent}>
        <p>1. Понятия, используемые в Правилах</p>
        <p>
          Участник — лицо, обладающее правоспособностью и достигшее возраста,
          позволяющего участвовать в Игре и/или заключать сделки. Человек,
          зарегистрированный в Российской Федерации и прошедший авторизацию
          через мессенджер Telegram.
          <br />
          Игра Sima-Tap — это интерактивное аудиовизуальное произведение,
          участие в котором регулируется Правилами и условиями.
        </p>
        <p>
          Лев — цифровой персонаж, которого игрок развивает в рамках Игры.
          <br />
          Игровая валюта — внутриигровая единица, которую игрок может
          заработать, выполняя действия. Игровая валюта не является денежным
          средством, и её использование ограничено рамками Правил и игровой
          механики.
          <br />
          Задание — это инструкции и/или задачи, выдаваемые игроку для
          выполнения и получения Игровой валюты в соответствии с Правилами Игры.
          Доступность Задания определяется случайным образом и не гарантируется.
          На выполнение Задания отведено ограниченное количество времени.
          Информация о сроках выполнения Задания и о количестве Игровой валюты,
          которую можно получить за выполнение, отображается на карточке Задания
          в интерфейсе Игры. Список доступных Заданий периодически обновляется.
          Игрок должен нажать кнопку «Принять задание» (или аналогичную по
          смыслу и функционалу), чтобы начать выполнение Задания. По завершении
          выполнения необходимо нажать кнопку «Проверить» (или аналогичную по
          смыслу и функционалу), отображаемую в интерфейсе Игры.
          <br />
          Тап — клик по Льву, в результате которого игрок получает игровую
          валюту в соответствии с Правилами.
          <br />
          Энергия — числовой показатель, который ограничивает количество Тапов,
          доступных игроку.
          <br />
          Друг — лицо, обладающее правоспособностью и достигшее возраста,
          позволяющего участвовать в Игре и/или заключать сделки. Человек,
          зарегистрированный в Российской Федерации и прошедший авторизацию
          через мессенджер Telegram.
          <br />
          Бустеры — вспомогательные элементы в Игре, которые можно приобрести за
          Игровую валюту в разделе «Бустеры» в магазине Сервиса. Подробные
          условия их получения и использования указаны в интерфейсе Игры.
          <br />
          Пассивный доход — показатель, который автоматически увеличивает
          количество игровых баллов в соответствии с условиями, указанными на
          соответствующей карточке в интерфейсе Игры.
        </p>

        <p>2. Механика Игры</p>
        <p>
          Получение Игровой валюты.
          <br />
          Игровую валюту можно получить одним из указанных ниже способов.
          <br />
          Тап.
          <br />
          За каждый Тап игрок получает 13 Игровых валют. При этом для совершения
          одного Тапа тратится 13 единиц энергии. Каждую секунду
          восстанавливается 1 единица энергии.
        </p>
        <p>
          Выполнение Заданий.
          <br />
          Игрок может зарабатывать Игровую валюту за выполнение Заданий. При
          этом единицы Энергии не расходуются, за исключением тех Заданий,
          которые включают в себя действия, связанные с получением
          дополнительных единиц Энергии или иными действиями, непосредственно
          относящимися к Энергии.
        </p>
        <p>
          Друзья.
          <br />
          Участник может получить Игровую валюту, отправив пригласительную
          ссылку Другу, который перешел по ней и выполнил одно из действий.
        </p>
        <p>
          Пассивный доход.
          <br />
          Участник Игры может автоматически получать дополнительную Игровую
          валюту с помощью Пассивного дохода. Заработок Игровой валюты через
          Пассивный доход возможен как при включенной, так и при выключенной
          Игре. В случае выключенной Игры автоматический заработок ограничен по
          времени и не может превышать 3 часов. Заработанная в период отключения
          Игры Игровая валюта начисляется Участнику при первом входе в Игру в
          количестве, указанном в интерфейсе.
        </p>

        <p>3. Дополнительные условия</p>
        <p>
          «Сима-ленд» оставляет за собой право приостанавливать или прекращать
          проведение данной Игры или отдельных её механик, указанных в Правилах,
          а также изменять их условия в любое время без объяснения причин.
        </p>
        <p>
          Пользователь Игры полностью и безоговорочно принимает Правила и
          Лицензионное соглашение на использование программы Sima-Tap.
        </p>
        <p>
          Для предотвращения недобросовестного получения Игровой валюты и
          Промокодов, а также злоупотреблений при их использовании, «Сима-ленд»
          оставляет за собой право применять методы проверки, не указанные
          напрямую в Правилах, чтобы убедиться в их соответствии. В случае
          обнаружения таких злоупотреблений или попыток недобросовестного
          получения Игровой валюты «Сима-ленд» вправе отказать в предоставлении
          Игровой валюты, Промокодов и любых вознаграждений.
        </p>
        <p>
          Для получения Игровой валюты, Бустеров, Пассивного дохода и Промокодов
          Участникам могут быть предложены действия, отличающиеся от ранее
          описанных условий.
        </p>
        <p>
          Авторизация в Игре с одного устройства разрешена только одному
          Участнику. Если количество Участников, прошедших авторизацию с одного
          устройства, превышает установленное ограничение, «Сима-ленд» имеет
          право ограничить или запретить доступ к Игре для всех Участников,
          авторизованных с данного устройства.
        </p>
        <p>
          «Сима-ленд» вправе ограничить или запретить доступ Участнику к Игре,
          если будут выявлены недобросовестные действия по отношению к компании
          «Сима-ленд» или другим Участникам Игры. «Сима-ленд» самостоятельно
          определяет критерии, по которым действия Участников считаются
          недобросовестными, и может не раскрывать эти критерии, а также способы
          их выявления.
        </p>
      </div>
    </div>
  );
};

export default RulesModal;
