// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@charset "UTF-8";
/* src/components/BoosterIndicator/BoosterIndicator.scss */
.booster-indicator {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  cursor: pointer;
}

.booster-icon {
  width: 30px;
  height: 30px;
}
.booster-icon svg path {
  fill: #ffe269 !important;
}

.booster_count {
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: #d9d9d9;
}

/* Удаляем стили, связанные с модалкой */
.booster_confirm-description {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-align: center;
}

.custom-toast {
  background-color: #ff4d4f !important;
  color: #ffffff !important;
}`, "",{"version":3,"sources":["webpack://./src/components/UI/BoosterIndicator/BoosterIndicator.scss"],"names":[],"mappings":"AAAA,gBAAgB;AAAhB,0DAAA;AAEA;EACE,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,SAAA;EACA,eAAA;AACF;;AAEA;EACE,WAAA;EACA,YAAA;AACF;AAAE;EACE,wBAAA;AAEJ;;AAEA;EACE,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,cAAA;AACF;;AAEA,wCAAA;AAEA;EACE,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,kBAAA;AAAF;;AAGA;EACE,oCAAA;EACA,yBAAA;AAAF","sourcesContent":["/* src/components/BoosterIndicator/BoosterIndicator.scss */\n\n.booster-indicator {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  gap: 10px;\n  cursor: pointer;\n}\n\n.booster-icon {\n  width: 30px;\n  height: 30px;\n  svg path {\n    fill: #ffe269 !important;\n  }\n}\n\n.booster_count {\n  font-weight: 600;\n  font-size: 14px;\n  line-height: 21px;\n  color: #d9d9d9;\n}\n\n/* Удаляем стили, связанные с модалкой */\n\n.booster_confirm-description {\n  font-size: 14px;\n  font-weight: 500;\n  line-height: 20px;\n  text-align: center;\n}\n\n.custom-toast {\n  background-color: #ff4d4f !important;\n  color: #ffffff !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
