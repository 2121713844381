// src/store/useModalStore.ts

import { create } from "zustand";
import { Quest } from "../types/Quest"; // Убедитесь, что путь корректен

interface ModalData {
  id: string;
  content: React.ReactNode;
  backgroundColor?: string;
}

interface ModalState {
  modalStack: ModalData[];
  selectedQuest: Quest | null;
  openModal: (content: React.ReactNode, backgroundColor?: string) => string;
  closeModal: (id?: string) => void;
  setSelectedQuest: (quest: Quest | null) => void;
}

const useModalStore = create<ModalState>((set) => ({
  modalStack: [],
  selectedQuest: null,

  openModal: (content, backgroundColor) => {
    const id = Math.random().toString(36).substr(2, 9);
    set((state) => ({
      modalStack: [...state.modalStack, { id, content, backgroundColor }],
    }));
    return id;
  },

  closeModal: (id) => {
    set((state) => {
      if (id) {
        return {
          modalStack: state.modalStack.filter((modal) => modal.id !== id),
          selectedQuest: null, // Сбрасываем выбранное задание при закрытии модалки
        };
      } else {
        // Если id не передан, удаляем последнее модальное окно
        return {
          modalStack: state.modalStack.slice(0, -1),
          selectedQuest: null,
        };
      }
    });
  },

  setSelectedQuest: (quest) => set({ selectedQuest: quest }),
}));

export default useModalStore;
