// src/components/UpgradesShop/UpgradeModalContent/UpgradeModalContent.tsx

import React, { useCallback } from "react";
import styles from "./UpgradeModalContent.module.scss";
import { Upgrade } from "../../../types/Upgrade";
import { ReactComponent as CoinIcon } from "../../../assets/icons/coin.svg";

interface UpgradeModalContentProps {
  upgrade: Upgrade;
  onPurchase: () => void;
  isPurchasing: boolean;
  isAffordable: boolean;
  isMaxed: boolean;
  onClose: () => void;
}

const UpgradeModalContent: React.FC<UpgradeModalContentProps> = React.memo(
  ({ upgrade, onPurchase, isPurchasing, isAffordable, isMaxed, onClose }) => {
    const handlePurchase = useCallback(async () => {
      if (!isPurchasing && isAffordable && !isMaxed) {
        await onPurchase();
        onClose(); // Закрываем модальное окно после покупки
      }
    }, [isPurchasing, isAffordable, isMaxed, onPurchase, onClose]);

    return (
      <div className={styles.upgradeModalContent}>
        {upgrade.next_level_cost !== null && (
          <div className={styles.booster_price_wrapper}>
            <CoinIcon className={styles.coinIcon} />
            <p className={styles.booster_price_title}>
              {upgrade.next_level_cost}
            </p>
          </div>
        )}

        {upgrade.url && (
          <picture className={styles.image_wrapper}>
            <source
              srcSet={require(`../../../assets/images/${upgrade.url.replace(
                /\.(png|jpg|jpeg|gif)$/,
                ".webp"
              )}`)}
              type="image/webp"
            />
            <img
              src={require(`../../../assets/images/${upgrade.url}`)}
              alt={upgrade.name}
              className={styles.image}
            />
          </picture>
        )}
        <h2 className={styles.title}>{upgrade.name}</h2>

        <p className={styles.level}>Уровень: {upgrade.current_level}</p>
        <p className={styles.income_info}>
          Ваш пассивный доход <br /> в час увеличится на:
        </p>
        <div className={styles.passive_income_wrapper}>
          <CoinIcon className={styles.coinIcon} />
          <p className={styles.passive_income_value}>
            +{upgrade.income_increase_per_level}
          </p>
        </div>

        <button
          className={`${styles.buy_btn} ${
            isMaxed || !isAffordable ? styles.disabled : ""
          }`}
          onClick={handlePurchase}
          disabled={isPurchasing || isMaxed || !isAffordable}
        >
          {isPurchasing
            ? "Покупка..."
            : isMaxed
            ? "Максимальный уровень"
            : !isAffordable
            ? "Недостаточно монет"
            : "Купить бустер"}
        </button>
      </div>
    );
  }
);

export default UpgradeModalContent;
