// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@charset "UTF-8";
/* src/components/UI/SharedContainer/SharedContainer.scss */
.main-page {
  display: flex;
  flex-direction: column;
  flex: 1 1;
  /* Удаляем overflow: hidden */
}

.container {
  flex: 1 1;
  height: calc(100vh - 120px - 60px); /* 100px — примерная высота хедера, 60px — футера */
  display: flex;
  flex-direction: column;
  background: linear-gradient(180deg, #2d3236 0%, #000000 100%);
  border-top-left-radius: 62px;
  border-top-right-radius: 62px;
  border-top: 3px solid #e2ba68;
  margin-top: 60px;
  padding: 20px;
  padding-bottom: 0;
  overflow-y: auto; /* Добавляем прокрутку */
  z-index: 100;
}

.inner_container {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  padding: 0; /* Убираем padding, если он конфликтует */
  justify-content: flex-start; /* Начинаем с верхней части */
}`, "",{"version":3,"sources":["webpack://./src/components/UI/SharedContainer/SharedContainer.scss"],"names":[],"mappings":"AAAA,gBAAgB;AAAhB,2DAAA;AAEA;EACE,aAAA;EACA,sBAAA;EACA,SAAA;EACA,6BAAA;AACF;;AAEA;EACE,SAAA;EACA,kCAAA,EAEG,mDAAA;EACH,aAAA;EACA,sBAAA;EACA,6DAAA;EACA,4BAAA;EACA,6BAAA;EACA,6BAAA;EACA,gBAAA;EACA,aAAA;EACA,iBAAA;EACA,gBAAA,EAAA,wBAAA;EACA,YAAA;AADF;;AAIA;EACE,SAAA;EACA,aAAA;EACA,sBAAA;EACA,UAAA,EAAA,yCAAA;EACA,2BAAA,EAAA,6BAAA;AADF","sourcesContent":["/* src/components/UI/SharedContainer/SharedContainer.scss */\n\n.main-page {\n  display: flex;\n  flex-direction: column;\n  flex: 1;\n  /* Удаляем overflow: hidden */\n}\n\n.container {\n  flex: 1;\n  height: calc(\n    100vh - 120px - 60px\n  ); /* 100px — примерная высота хедера, 60px — футера */\n  display: flex;\n  flex-direction: column;\n  background: linear-gradient(180deg, #2d3236 0%, #000000 100%);\n  border-top-left-radius: 62px;\n  border-top-right-radius: 62px;\n  border-top: 3px solid #e2ba68;\n  margin-top: 60px;\n  padding: 20px;\n  padding-bottom: 0;\n  overflow-y: auto; /* Добавляем прокрутку */\n  z-index: 100;\n}\n\n.inner_container {\n  flex: 1;\n  display: flex;\n  flex-direction: column;\n  padding: 0; /* Убираем padding, если он конфликтует */\n  justify-content: flex-start; /* Начинаем с верхней части */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
