// src/store/useCoinStore.ts

import { create } from "zustand";
import { persist, PersistOptions } from "zustand/middleware";
import { io, Socket } from "socket.io-client";
import { AppUser } from "../types/User";
import { Upgrade } from "../types/Upgrade";
import axios from "axios";

// Проверяем, используем ли мы моковые данные
const isMockMode = process.env.REACT_APP_USE_MOCK_DATA === "true";

// Определяем ключ для persist
const storageKey = isMockMode ? "coin-storage-mock-v2" : "coin-storage-v2";

// Если в режиме моковых данных, очищаем 'coin-storage-mock-v2'
if (isMockMode) {
  localStorage.removeItem(storageKey);
}

interface CoinStoreState {
  coins: number;
  coinsPerClick: number;
  passiveIncomeRate: number;
  energy: number;
  maxEnergy: number;
  availableBoosters: number;
  totalBoosters: number;
  userId: number | null;
  offlineIncome: number;
  upgrades: Upgrade[];
  socket: Socket | null;
  isPurchasing: number | null;
  setUpgrades: (upgrades: Upgrade[]) => void;
  initializeStore: (user: AppUser) => void;
  initializeSocket: () => void;
  sendTap: () => void;
  reconnectSocket: () => void;
  disconnectSocket: () => void;
  addCoins: () => Promise<void>;
  incrementCoins: (amount: number) => void;
  decrementEnergy: (amount: number) => void;
  activateBoost: () => void;
  purchaseUpgrade: (id: number) => Promise<void>;
  setOfflineIncome: (amount: number) => void;
  setPassiveIncomeRate: (rate: number) => void;
  setCoins: (amount: number) => void; // Добавлено
}

const useCoinStore = create<CoinStoreState>()(
  persist(
    (set, get) => ({
      coins: 0,
      coinsPerClick: 13,
      passiveIncomeRate: 0,
      energy: 2000,
      maxEnergy: 2000,
      availableBoosters: 6,
      totalBoosters: 6,
      userId: null,
      offlineIncome: 0,
      upgrades: [],
      socket: null,
      isPurchasing: null,

      setUpgrades: (upgrades: Upgrade[]) => {
        const totalPassiveIncome = upgrades.reduce(
          (sum, upgrade) => sum + (upgrade.cumulative_income || 0),
          0
        );
        set({ upgrades, passiveIncomeRate: totalPassiveIncome });
      },

      initializeStore: (user: AppUser) => {
        set((state) => {
          if (state.userId) {
            return {};
          }

          const updatedState = {
            coins: user.coins || 0,
            energy: user.energy_left !== undefined ? user.energy_left : 2000,
            availableBoosters:
              user.boosts_left !== undefined ? user.boosts_left : 6,
            userId: user.id,
          };

          console.log("Store initialized with user:", updatedState);

          return updatedState;
        });

        const currentState = get();
        console.log(
          "Current userId before socket initialization:",
          currentState.userId
        );
        currentState.initializeSocket();
      },

      initializeSocket: () => {
        const state = get();
        const { userId } = state;

        if (!userId) {
          console.error(
            "Не удалось получить userId для подключения к WebSocket."
          );
          return;
        }

        const socket = io("wss://dev.simatap.ru", {
          path: "/socket.io",
          transports: ["websocket"],
          withCredentials: true,
        });

        console.log(userId, "WebSocket connected");

        socket.on("connect", () => {
          socket.emit("register", { userId });
        });

        socket.on("energyUpdated", (data) => {
          if (data.energy_left !== undefined) {
            set({ energy: data.energy_left });
          } else {
            console.warn("Обновление энергии не содержит данных:", data);
          }
        });

        socket.on("passiveIncomePerHour", (data) => {
          if (data.passive_income_per_hour !== undefined) {
            set({ passiveIncomeRate: data.passive_income_per_hour });
          } else {
            console.warn(
              "Обновление пассивного дохода не содержит данных:",
              data
            );
          }
        });

        socket.on("boostsUpdated", (data) => {
          if (data.boosts_left !== undefined) {
            set({ availableBoosters: data.boosts_left });
          } else {
            console.warn("Обновление бустов не содержит данных:", data);
          }
        });

        socket.on("coinsUpdated", (data) => {
          if (data.coins !== undefined) {
            set({ coins: data.coins });
          } else {
            console.warn("Обновление монет не содержит данных:", data);
          }
        });

        socket.on("connect_error", (error) => {
          console.error(
            `Ошибка подключения WebSocket для пользователя ${userId}:`,
            error
          );
        });

        socket.on("disconnect", () => {
          get().reconnectSocket();
        });

        set({ socket });
      },

      reconnectSocket: () => {
        const state = get();
        if (state.socket) {
          state.socket.connect();
        } else {
          state.initializeSocket();
        }
      },

      activateBoost: () => {
        const state = get();
        const { socket, userId } = state;

        if (socket && userId) {
          socket.emit("useBoost", { userId });
        } else {
          console.error(
            "Не удалось использовать буст: сокет не подключен или userId не установлен."
          );
        }
      },

      sendTap: () => {
        const state = get();
        const { socket, userId, coinsPerClick, energy } = state;

        if (socket && userId && energy >= coinsPerClick) {
          socket.emit("tap", { userId });
        } else {
          console.error("Недостаточно энергии или сокет не инициализирован.");
        }
      },

      decrementEnergy: (amount: number) => {
        set((state) => ({ energy: state.energy - amount }));
      },

      disconnectSocket: () => {
        const state = get();
        const { socket } = state;
        if (socket) {
          socket.disconnect();
          set({ socket: null });
        }
      },

      addCoins: async () => {
        const state = get();
        const { coinsPerClick, energy, userId } = state;

        if (userId === null) {
          console.error("Пользователь не авторизован.");
          return;
        }

        if (energy < coinsPerClick) {
          console.error("Недостаточно энергии для добавления монет.");
          return;
        }

        state.sendTap();
      },

      incrementCoins: (amount: number) => {
        set((state) => ({ coins: state.coins + amount }));
      },

      setCoins: (amount: number) => {
        set({ coins: amount });
      },

      purchaseUpgrade: async (id: number): Promise<void> => {
        const state = get();
        const { userId } = state;

        if (!userId) {
          console.error("Пользователь не авторизован.");
          throw new Error("Пользователь не авторизован.");
        }

        set({ isPurchasing: id });

        try {
          const response = await axios.post(
            `${process.env.REACT_APP_API_URL}api/upgrades/purchase`,
            null,
            {
              params: {
                userId: userId,
                upgradeId: id,
              },
            }
          );

          if (response.status === 200) {
            const { upgrades } = response.data;
            set({ upgrades, isPurchasing: null });
          } else {
            console.error("Ошибка при покупке апгрейда:", response.statusText);
            set({ isPurchasing: null });
            throw new Error(response.statusText);
          }
        } catch (error: any) {
          console.error("Ошибка при покупке апгрейда:", error);
          set({ isPurchasing: null });
          throw error;
        }
      },

      setOfflineIncome: (amount: number) => {
        set({ offlineIncome: amount });
      },

      setPassiveIncomeRate: (rate: number) => {
        set({ passiveIncomeRate: rate });
      },
    }),
    {
      name: storageKey,
      partialize: (state: CoinStoreState) => ({
        coins: state.coins,
        energy: state.energy,
        passiveIncomeRate: state.passiveIncomeRate,
        availableBoosters: state.availableBoosters,
        offlineIncome: state.offlineIncome,
        upgrades: state.upgrades,
      }),
    } as PersistOptions<CoinStoreState, unknown>
  )
);

export default useCoinStore;
