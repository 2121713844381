// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.RulesModal_modal_wrapper__m51wp {
  padding-top: 70px;
}

.RulesModal_modalTitle__axtHF {
  font-size: 32px;
  font-weight: 700;
  line-height: 30px;
  text-align: center;
  color: #f1f1f1;
  margin-bottom: 30px;
}

.RulesModal_modalContent__GXjNT p {
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  color: #888888;
  margin-bottom: 20px;
}`, "",{"version":3,"sources":["webpack://./src/components/Header/HeaderModalContent/RulesModal/RulesModal.module.scss"],"names":[],"mappings":"AAAA;EACE,iBAAA;AACF;;AAEA;EACE,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,kBAAA;EACA,cAAA;EACA,mBAAA;AACF;;AAGE;EACE,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,gBAAA;EACA,cAAA;EACA,mBAAA;AAAJ","sourcesContent":[".modal_wrapper {\n  padding-top: 70px;\n}\n\n.modalTitle {\n  font-size: 32px;\n  font-weight: 700;\n  line-height: 30px;\n  text-align: center;\n  color: #f1f1f1;\n  margin-bottom: 30px;\n}\n\n.modalContent {\n  p {\n    font-size: 16px;\n    font-weight: 500;\n    line-height: 20px;\n    text-align: left;\n    color: #888888;\n    margin-bottom: 20px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modal_wrapper": `RulesModal_modal_wrapper__m51wp`,
	"modalTitle": `RulesModal_modalTitle__axtHF`,
	"modalContent": `RulesModal_modalContent__GXjNT`
};
export default ___CSS_LOADER_EXPORT___;
