// src/components/UI/QuestModalContent.tsx

import React, {
  useState,
  useEffect,
  useCallback,
  useContext,
  useRef,
} from "react";
import axios from "axios";
import styles from "./QuestModalContent.module.scss";
import { ReactComponent as CoinIcon } from "../../assets/icons/coin.svg";
import { ReactComponent as DoneIcon } from "../../assets/icons/done-icon.svg";
import { CoinEffectContext } from "../../App";
import SimaLogo from "../../assets/images/sima-logo.png";
import SimaLiveLogo from "../../assets/images/sima-live-logo.jpg";
import useModalStore from "../../store/useModalStore";
import { useUserStore } from "../../store/useUserStore";

interface QuestModalContentProps {
  onComplete: (taskId: number) => void;
  onClose: () => void;
  updateQuests: () => void;
}

const QuestModalContent: React.FC<QuestModalContentProps> = ({
  onComplete,
  onClose,
  updateQuests,
}) => {
  const { selectedQuest, setSelectedQuest } = useModalStore();
  const { user } = useUserStore();
  const userId = user?.id;
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [remainingTime, setRemainingTime] = useState<number>(
    selectedQuest?.timeRemaining || 0
  );
  const { triggerCoinEffect } = useContext(CoinEffectContext);
  const isSubscribedRef = useRef<boolean | null>(null);
  const [errorMessage, setErrorMessage] = useState<string>("");

  useEffect(() => {
    setRemainingTime(selectedQuest?.timeRemaining || 0);
  }, [selectedQuest]);

  const formatTime = (seconds: number): string => {
    const mins = Math.floor(seconds / 60)
      .toString()
      .padStart(2, "0");
    const secs = (seconds % 60).toString().padStart(2, "0");
    return `${mins}:${secs}`;
  };

  // Функция для форматирования чисел
  const formatNumber = (num: number): string => {
    return new Intl.NumberFormat("ru-RU").format(num);
  };

  // Функция для проверки подписки на канал Telegram
  const checkSubscription = useCallback(async (): Promise<boolean> => {
    try {
      const response = await axios.get(
        `https://api.telegram.org/bot7466294805:AAF2VvM1P1E0WtVapCNxfn1znldqvgZ8y04/getChatMember`,
        {
          params: {
            chat_id: "@simalandlive",
            user_id: userId,
          },
        }
      );

      const { ok, error_code, description } = response.data;
      if (
        !ok &&
        error_code === 400 &&
        description === "Bad Request: PARTICIPANT_ID_INVALID"
      ) {
        isSubscribedRef.current = false;
        return false;
      } else {
        isSubscribedRef.current = true;
        return true;
      }
    } catch (error) {
      console.error("Ошибка при проверке подписки:", error);
      return false;
    }
  }, [userId]);

  // Функция для назначения задания
  const handleAssignTask = useCallback(async () => {
    if (!selectedQuest || !userId) return;

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}api/tasks/assign/${userId}/${selectedQuest.taskId}`
      );
      const data = response.data;
      if (data.message === "Задание успешно назначено.") {
        await updateQuests();
      } else {
        setErrorMessage(data.message || "Не удалось назначить задание.");
      }
    } catch (error) {
      console.error("Ошибка при назначении задания:", error);
      setErrorMessage("Произошла ошибка при назначении задания.");
    }
  }, [selectedQuest, userId, updateQuests]);

  // Логика для задания типа "confirmation"
  const handleConfirmationTask = useCallback(async () => {
    if (!selectedQuest || !userId) return;

    setIsLoading(true);
    setErrorMessage("");

    try {
      if (selectedQuest.status === "not_assigned") {
        await handleAssignTask();
      }

      const subscribed = await checkSubscription();
      if (!subscribed) {
        setErrorMessage("Вы еще не подписаны!");
        return;
      }

      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}api/tasks/confirm/${userId}/${selectedQuest.taskId}`
      );

      const data = response.data;

      if (data.message === "Задание успешно подтверждено.") {
        const coinsAdded: number | undefined = data.reward;
        if (coinsAdded !== undefined) {
          triggerCoinEffect();
          onComplete(selectedQuest.taskId);
          setTimeout(() => {
            onClose();
            updateQuests();
            setSelectedQuest(null);
          }, 2000);
        }
      } else {
        setErrorMessage(data.message || "Статус задания неизвестен.");
      }
    } catch (error) {
      console.error("Ошибка при выполнении задания:", error);
      if (axios.isAxiosError(error)) {
        if (error.response) {
          const { status, data } = error.response;

          if (status === 400) {
            // Предполагается, что сервер возвращает поле message
            const serverMessage =
              data?.message || "У вас недостаточно рефералов!";
            setErrorMessage(serverMessage);
          } else if (data?.message) {
            setErrorMessage(data.message);
          } else {
            setErrorMessage("Произошла ошибка при подтверждении задания.");
          }
        } else if (error.request) {
          setErrorMessage(
            "Нет ответа от сервера. Проверьте ваше интернет-соединение."
          );
        } else {
          setErrorMessage("Произошла непредвиденная ошибка.");
        }
      } else {
        setErrorMessage("Произошла непредвиденная ошибка.");
      }
    } finally {
      setIsLoading(false);
    }
  }, [
    selectedQuest,
    userId,
    handleAssignTask,
    checkSubscription,
    triggerCoinEffect,
    onComplete,
    onClose,
    updateQuests,
    setSelectedQuest,
  ]);

  // Логика для задания типа "referral"
  const handleReferralTask = useCallback(async () => {
    if (!selectedQuest || !userId) return;

    setIsLoading(true);
    setErrorMessage("");

    try {
      if (selectedQuest.status === "not_assigned") {
        await handleAssignTask();
      }
      console.log(selectedQuest);

      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}api/tasks/confirm/${userId}/${selectedQuest.taskId}`
      );

      const data = response.data;

      if (data.message === "Задание успешно подтверждено.") {
        const coinsAdded: number | undefined = data.reward;
        if (coinsAdded !== undefined) {
          triggerCoinEffect();
          onComplete(selectedQuest.taskId);
          setTimeout(() => {
            onClose();
            updateQuests();
            setSelectedQuest(null);
          }, 2000);
        }
      } else {
        setErrorMessage(data.message || "Статус задания неизвестен.");
      }
    } catch (error) {
      console.error("Ошибка при выполнении задания:", error);
      if (axios.isAxiosError(error)) {
        if (error.response) {
          const { status, data } = error.response;

          if (status === 400) {
            // Предполагается, что сервер возвращает поле message
            const serverMessage =
              data?.message || "У вас недостаточно рефералов!";
            setErrorMessage(serverMessage);
          } else if (data?.message) {
            setErrorMessage(data.message);
          } else {
            setErrorMessage("Произошла ошибка при подтверждении задания.");
          }
        } else if (error.request) {
          setErrorMessage(
            "Нет ответа от сервера. Проверьте ваше интернет-соединение."
          );
        } else {
          setErrorMessage("Произошла непредвиденная ошибка.");
        }
      } else {
        setErrorMessage("Произошла непредвиденная ошибка.");
      }
    } finally {
      setIsLoading(false);
    }
  }, [
    selectedQuest,
    userId,
    handleAssignTask,
    triggerCoinEffect,
    onComplete,
    onClose,
    updateQuests,
    setSelectedQuest,
  ]);

  // Логика для задания типа "timer" (кнопка "Скачать")
  const handleDownloadButtonClick = useCallback(async () => {
    if (!selectedQuest || !userId) return;

    let downloadUrl = "https://sima-land.ru/download";

    if (
      /iPad|iPhone|iPod/.test(navigator.userAgent) &&
      !("MSStream" in window)
    ) {
      downloadUrl =
        "https://apps.apple.com/ru/app/сима-ленд-интернет-магазин/id1057565689";
    } else if (/android/i.test(navigator.userAgent)) {
      downloadUrl =
        "https://play.google.com/store/apps/details?id=ru.sima_land.spb.market&hl=ru&pli=1";
    }

    window.open(downloadUrl, "_blank");

    if (selectedQuest.status === "not_assigned") {
      setIsLoading(true);
      setErrorMessage("");
      try {
        await handleAssignTask();
        await updateQuests();
        setRemainingTime(selectedQuest.timeRemaining || 3600);
      } catch (error) {
        console.error("Ошибка при назначении задания:", error);
        setErrorMessage("Произошла ошибка при назначении задания.");
      } finally {
        setIsLoading(false);
      }
    }
  }, [selectedQuest, userId, handleAssignTask, updateQuests]);

  // Логика для задания типа "timer" (кнопка "Проверить")
  const handleTimerCheckButtonClick = useCallback(async () => {
    if (!selectedQuest || !userId) return;

    setIsLoading(true);
    setErrorMessage("");

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}api/tasks/confirm/${userId}/${selectedQuest.taskId}`
      );

      const data = response.data;

      if (data.message === "Задание успешно подтверждено.") {
        const coinsAdded: number | undefined = data.reward;
        if (coinsAdded !== undefined) {
          triggerCoinEffect();
          onComplete(selectedQuest.taskId);
          setTimeout(() => {
            onClose();
            updateQuests();
            setSelectedQuest(null);
          }, 2000);
        }
      } else {
        setErrorMessage(data.message || "Статус задания неизвестен.");
      }
    } catch (error) {
      console.error("Ошибка при выполнении задания:", error);
      if (axios.isAxiosError(error)) {
        if (error.response) {
          const { status, data } = error.response;

          if (status === 400) {
            const serverMessage =
              data?.message || "У вас недостаточно рефералов!";
            setErrorMessage(serverMessage);
          } else if (data?.message) {
            setErrorMessage(data.message);
          } else {
            setErrorMessage("Произошла ошибка при подтверждении задания.");
          }
        } else if (error.request) {
          setErrorMessage(
            "Нет ответа от сервера. Проверьте ваше интернет-соединение."
          );
        } else {
          setErrorMessage("Произошла непредвиденная ошибка.");
        }
      } else {
        setErrorMessage("Произошла непредвиденная ошибка.");
      }
    } finally {
      setIsLoading(false);
    }
  }, [
    selectedQuest,
    userId,
    triggerCoinEffect,
    onComplete,
    onClose,
    updateQuests,
    setSelectedQuest,
  ]);

  // Таймер для задания типа "timer"
  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (remainingTime > 0) {
      timer = setInterval(() => {
        setRemainingTime((prevTime) => {
          if (prevTime <= 1) {
            clearInterval(timer);
            return 0;
          }
          return prevTime - 1;
        });
      }, 1000);
    }
    return () => {
      if (timer) clearInterval(timer);
    };
  }, [remainingTime]);

  const renderIcon = () => {
    if (selectedQuest?.status === "completed") {
      return <DoneIcon className={styles.modalIcon} />;
    }
    const iconSrc =
      selectedQuest?.icon === "sima-live-logo.jpg" ? SimaLiveLogo : SimaLogo;
    return (
      <img
        src={iconSrc}
        alt={`${selectedQuest?.title} icon`}
        className={styles.modalIcon}
      />
    );
  };

  const renderContent = () => {
    if (!selectedQuest) return null;

    switch (selectedQuest.type) {
      case "confirmation":
        return (
          <div
            className={`${styles.confirmation_wrapper} ${styles.content_wrapper}`}
          >
            <h2>{selectedQuest.title}</h2>
            <div className={styles.reward_wrapper}>
              <CoinIcon className={styles.coinIcon} />
              <p className={styles.reward_amount}>
                + {formatNumber(selectedQuest.reward)}
              </p>
            </div>
            <button
              className={styles.primaryButton}
              onClick={() => {
                window.open("https://t.me/simalandlive", "_blank");
              }}
              disabled={isLoading}
            >
              Подписаться
            </button>
            <button
              className={styles.secondaryButton}
              onClick={handleConfirmationTask}
              disabled={isLoading}
            >
              {isLoading ? "Проверка..." : "Проверить"}
            </button>
            {errorMessage && (
              <div className={styles.errorMessage}>{errorMessage}</div>
            )}
          </div>
        );

      case "timer":
        return (
          <div className={`${styles.timer_wrapper} ${styles.content_wrapper}`}>
            <h2>{selectedQuest.title}</h2>
            <div className={styles.reward_wrapper}>
              <CoinIcon className={styles.coinIcon} />
              <p className={styles.reward_amount}>
                + {formatNumber(selectedQuest.reward)}
              </p>
            </div>
            <button
              className={styles.primaryButton}
              onClick={handleDownloadButtonClick}
              disabled={isLoading}
            >
              {isLoading ? "Загрузка..." : "Скачать"}
            </button>
            {remainingTime > 0 ? (
              <button className={styles.timerButton} disabled>
                {formatTime(remainingTime)}
              </button>
            ) : (
              <button
                className={styles.secondaryButton}
                onClick={handleTimerCheckButtonClick}
                disabled={isLoading}
              >
                {isLoading ? "Проверка..." : "Проверить"}
              </button>
            )}
            {errorMessage && (
              <div className={styles.errorMessage}>{errorMessage}</div>
            )}
          </div>
        );

      case "referral":
        return (
          <div
            className={`${styles.referral_wrapper} ${styles.content_wrapper}`}
          >
            <h2>{selectedQuest.title}</h2>
            <div className={styles.reward_wrapper}>
              <CoinIcon className={styles.coinIcon} />
              <p className={styles.reward_amount}>
                + {formatNumber(selectedQuest.reward)}
              </p>
            </div>
            <button
              className={styles.primaryButton}
              onClick={handleReferralTask}
              disabled={isLoading}
            >
              {isLoading ? "Проверка..." : "Проверить"}
            </button>
            {errorMessage && (
              <div className={styles.errorMessage}>{errorMessage}</div>
            )}
          </div>
        );

      default:
        return null;
    }
  };

  return (
    <div className={styles.questModalContent}>
      {renderIcon()}
      {renderContent()}
    </div>
  );
};

export default QuestModalContent;
