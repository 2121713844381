// src/pages/ShopPage/ShopPage.tsx

import React, { useState, useCallback, Suspense, lazy } from "react";
import styles from "./ShopPage.module.scss";

import UpgradesShop from "../../components/UpgradesShop/UpgradesShop";
const ItemsShop = lazy(() => import("../../components/ItemsShop/ItemsShop"));

const ShopPage: React.FC = React.memo(() => {
  const [activeTab, setActiveTab] = useState<"items" | "leveling">("items");

  // Функция для вызова вибрации
  const triggerVibration = useCallback(
    (style: "light" | "medium" | "heavy" | "rigid" | "soft") => {
      if (
        window.Telegram &&
        window.Telegram.WebApp &&
        window.Telegram.WebApp.HapticFeedback &&
        typeof window.Telegram.WebApp.HapticFeedback.impactOccurred ===
          "function"
      ) {
        window.Telegram.WebApp.HapticFeedback.impactOccurred(style);
      } else {
        // Фолбэк на стандартную вибрацию, если API недоступен
        if (navigator.vibrate) {
          navigator.vibrate(50); // Вибрация на 50 мс
        }
      }
    },
    []
  );

  const handleTabChange = useCallback(
    (tab: "items" | "leveling") => {
      setActiveTab(tab);
      triggerVibration("soft"); // Вызываем soft вибрацию при переключении вкладок
    },
    [triggerVibration]
  );

  return (
    <div className={styles.shopPage}>
      <h1 className={styles.shop_title}>Магазин</h1>
      <div className={styles.tabNavigation}>
        <button
          className={`${styles.tabButton} ${
            activeTab === "items" ? styles.active : ""
          }`}
          onClick={() => handleTabChange("items")}
        >
          Товары и скидки
        </button>
        <button
          className={`${styles.tabButton} ${
            activeTab === "leveling" ? styles.active : ""
          }`}
          onClick={() => handleTabChange("leveling")}
        >
          Бустеры
        </button>
      </div>

      <div className={styles.scrollContainer}>
        <div className={styles.tabContent}>
          <Suspense fallback={<div>Загрузка...</div>}>
            {activeTab === "items" && <ItemsShop />}
            {activeTab === "leveling" && <UpgradesShop />}
          </Suspense>
        </div>
      </div>
    </div>
  );
});

export default ShopPage;
