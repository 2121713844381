import React from "react";
import styles from "./LicenseModal.module.scss";

const LicenseModal: React.FC = () => {
  return (
    <div className={`${styles.modal_wrapper} ${styles.licenseModal}`}>
      <h2 className={styles.modalTitle}>Лицензионное соглашение</h2>
      <div className={styles.modalContent}>
        <p>Лицензионное Соглашение Пользователя (EULA) для Игры "Simatap"</p>
        <p>Последнее обновление: 14.10.2024</p>
        <p>
          Пожалуйста, внимательно прочитайте это Лицензионное Соглашение (далее
          - "Соглашение") перед использованием игры "Simatap" (далее - "Игра").
        </p>
        <p>
          Устанавливая и используя Игра, вы соглашаетесь с условиями,
          изложенными в данном Соглашении. Если вы не согласны с этими
          условиями, не устанавливайте и не используйте Игра.
        </p>
        <p>1. Предоставление Лицензии</p>
        <p>
          Настоящее Соглашение предоставляет вам личную, ограниченную,
          непередаваемую и неэксклюзивную лицензию на установку и использование
          Игры только для личных, некоммерческих целей. Вы не можете копировать,
          изменять, распространять, продавать или сдавать в аренду Игру и любые
          её части без письменного разрешения правообладателя.
        </p>
        <p>2. Права Интеллектуальной Собственности</p>
        <p>
          Все права на Игру, включая, но не ограничиваясь, текстами, графикой,
          музыкой, интерфейсом и исходным кодом, принадлежат [Название
          Компании/Разработчика] и защищены законами об авторском праве и
          международными соглашениями.
          <br />
          Любое несанкционированное использование или копирование материалов из
          Игры является нарушением прав интеллектуальной собственности и может
          привести к гражданской и уголовной ответственности.
        </p>
        <p>3. Использование Виртуальной Валюты и Предметов</p>
        <p>
          В Игре предусмотрена внутренняя валюта и виртуальные предметы (далее -
          "Виртуальные Товары"). Виртуальные Товары можно приобретать за
          реальные деньги или зарабатывать в процессе игры. Все Виртуальные
          Товары остаются собственностью разработчика, и игроки получают лишь
          ограниченное право на их использование в рамках Игры.
          <br />
          Виртуальные Товары не имеют денежного эквивалента и не могут быть
          обменяны на реальные деньги, переданы другим игрокам или проданы.
          Разработчик оставляет за собой право изменять стоимость и доступность
          Виртуальных Товаров.
        </p>
        <p>4. Обновления и Изменения Игры</p>
        <p>
          Разработчик оставляет за собой право в любое время обновлять,
          изменять, исправлять ошибки или прекращать поддержку Игры без
          предварительного уведомления.
          <br />
          Обновления могут добавлять новые функции или изменять существующие.
          Установка и использование обновлений регулируется условиями настоящего
          Соглашения.
        </p>
        <p>5. Ограничения и Запрещенные Действия</p>
        <p>
          Запрещено использовать Игру для нелегальной деятельности или нарушения
          прав других игроков.
          <br />
          Вы не имеете права:
          <br />
          модифицировать или взламывать Игру,
          <br />
          использовать сторонние программы для автоматизации игрового процесса,
          <br />
          пытаться обойти системы защиты Игры.
          <br />
          Нарушение данных условий может привести к блокировке аккаунта без
          возврата средств.
        </p>
        <p>6. Конфиденциальность и Персональные Данные</p>
        <p>
          Игра может собирать персональные данные игроков (например, адрес
          электронной почты, IP-адрес и прочее) в соответствии с Политикой
          конфиденциальности [Название Компании/Разработчика].
          <br />
          Устанавливая Игру, вы соглашаетесь с условиями обработки ваших данных,
          описанными в Политике конфиденциальности. Политика доступна по адресу:
          [URL Политики конфиденциальности].
        </p>
        <p>7. Ограничение Ответственности</p>
        <p>
          Игра предоставляется "как есть" без каких-либо гарантий. [Название
          Компании/Разработчика] не несет ответственности за возможные сбои,
          ошибки, потерю данных, ущерб устройству или иному имуществу
          пользователя.
          <br />
          [Название Компании/Разработчика] не несет ответственности за любые
          косвенные, случайные, особые или побочные убытки, связанные с
          использованием Игры.
        </p>
        <p>8. Изменения в Лицензионном Соглашении</p>
        <p>
          [Название Компании/Разработчика] оставляет за собой право вносить
          изменения в настоящее Соглашение. Новая версия Соглашения вступает в
          силу с момента её публикации на сайте или внутри Игры. Продолжение
          использования Игры означает ваше согласие с изменениями.
        </p>
        <p>9. Прекращение Лицензии</p>
        <p>
          Лицензия, предоставленная вам по настоящему Соглашению, прекращается
          автоматически при нарушении любого из условий Соглашения. В случае
          прекращения лицензии вы обязаны удалить Игру со всех устройств и
          прекратить её использование.
        </p>
        <p>10. Применимое Право и Юрисдикция</p>
        <p>
          Настоящее Соглашение регулируется законодательством РФ. Все споры,
          возникающие в связи с настоящим Соглашением, подлежат разрешению в
          судах РФ.
        </p>
      </div>
    </div>
  );
};

export default LicenseModal;
