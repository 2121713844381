// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.formatted-number {
  display: flex;
  align-items: center;
}

.number-group {
  font-size: 40px;
  color: #f1f1f1;
  font-weight: 700;
  line-height: 1;
  margin: 0;
  padding: 0;
  display: inline-block;
  transition: transform 0.3s ease, color 0.3s ease;
}

.space {
  width: 2px;
}`, "",{"version":3,"sources":["webpack://./src/components/UI/FormattedNumber/FormattedNumber.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;AACF;;AAEA;EACE,eAAA;EACA,cAAA;EACA,gBAAA;EACA,cAAA;EACA,SAAA;EACA,UAAA;EACA,qBAAA;EAGA,gDAAA;AADF;;AASA;EACE,UAAA;AANF","sourcesContent":[".formatted-number {\n  display: flex;\n  align-items: center;\n}\n\n.number-group {\n  font-size: 40px;\n  color: #f1f1f1; // Более контрастный цвет\n  font-weight: 700;\n  line-height: 1;\n  margin: 0;\n  padding: 0;\n  display: inline-block;\n\n  // Плавный переход для свойств трансформации и цвета\n  transition: transform 0.3s ease, color 0.3s ease;\n}\n\n//.number-group.animate {\n//  transform: scale(1.2); // Увеличение размера\n//  color: #ff5722; // Изменение цвета для акцента\n//}\n\n.space {\n  width: 2px; // Увеличенный отступ для лучшей читаемости\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
