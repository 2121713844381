// src/components/UI/CoinEffect/CoinEffect.tsx

import React, { useEffect, useMemo } from "react";
import ReactDOM from "react-dom";
import styles from "./CoinEffect.module.scss";
import coinImage from "../../../assets/icons/coin.svg";

interface CoinEffectProps {
  onComplete: () => void;
}

const CoinEffect: React.FC<CoinEffectProps> = ({ onComplete }) => {
  const coinCount = 30;
  const animationDuration = 500;

  useEffect(() => {
    const timer = setTimeout(onComplete, animationDuration);
    return () => clearTimeout(timer);
  }, [onComplete, animationDuration]);

  const coins = useMemo(
    () =>
      Array.from({ length: coinCount }).map((_, index) => {
        const xOffset = Math.random() * 300 - 150;
        const yOffset = Math.random() * 300 - 150;
        const delay = Math.random() * 200;

        return (
          <img
            key={index}
            src={coinImage}
            alt="Coin"
            className={styles.coin}
            style={
              {
                "--move-x": `${xOffset}px`,
                "--move-y": `${yOffset}px`,
                "--delay": `${delay}ms`,
              } as React.CSSProperties
            }
          />
        );
      }),
    [coinCount]
  );

  return ReactDOM.createPortal(
    <div className={styles.coinEffect}>{coins}</div>,
    document.getElementById("coin-effect-root") as HTMLElement
  );
};

export default React.memo(CoinEffect);
