// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.formatted-number {
  display: flex;
  align-items: center;
  gap: 3px;
}

.number {
  font-size: 17px;
  font-weight: 600;
  line-height: 21px;
  text-align: right;
  color: #f1f1f1;
}

.coin_icon {
  width: 20px;
  height: 20px;
}`, "",{"version":3,"sources":["webpack://./src/components/UI/FormattedNumberHeader/FormattedNumberHeader.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,QAAA;AACF;;AAEA;EACE,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,iBAAA;EACA,cAAA;AACF;;AAEA;EACE,WAAA;EACA,YAAA;AACF","sourcesContent":[".formatted-number {\n  display: flex;\n  align-items: center;\n  gap: 3px;\n}\n\n.number {\n  font-size: 17px;\n  font-weight: 600;\n  line-height: 21px;\n  text-align: right;\n  color: #f1f1f1;\n}\n\n.coin_icon {\n  width: 20px;\n  height: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
