import React, { useEffect, useState } from "react";
import "./FormattedNumber.scss";

interface FormattedNumberProps {
  number: number;
}

const FormattedNumber: React.FC<FormattedNumberProps> = ({ number }) => {
  const formattedNumber = number
    .toLocaleString("ru-RU")
    .replace(/\u00A0/g, " "); // Заменяем неразрывный пробел на обычный

  const numberGroups = formattedNumber.split(" ");

  const [prevNumber, setPrevNumber] = useState<number>(number);
  const [animate, setAnimate] = useState<boolean>(false);

  useEffect(() => {
    if (number !== prevNumber) {
      setAnimate(true);
      setPrevNumber(number);
      const timer = setTimeout(() => setAnimate(false), 300); // Длительность анимации
      return () => clearTimeout(timer);
    }
  }, [number, prevNumber]);

  return (
    <div className="formatted-number">
      {numberGroups.map((group, index) => (
        <React.Fragment key={index}>
          <span className={`number-group ${animate ? "animate" : ""}`}>
            {group}
          </span>
          {index < numberGroups.length - 1 && <span className="space" />}
        </React.Fragment>
      ))}
    </div>
  );
};

export default React.memo(FormattedNumber);
