// src/pages/LeaderBoardPage/LeaderBoardPage.tsx

import React, { useEffect, useState, useCallback, useMemo } from "react";
import axios, { CancelTokenSource } from "axios";
import styles from "./LeaderBoardPage.module.scss";

// Импортируем PNG как обычные изображения
import firstPlaceIcon from "../../assets/icons/first-place.webp";
import secondPlaceIcon from "../../assets/icons/second-place.webp";
import thirdPlaceIcon from "../../assets/icons/third-place.webp";

// Импортируем SVG иконку рубля
import { ReactComponent as RubleIcon } from "../../assets/icons/ruble.svg";
import { ReactComponent as CoinIcon } from "../../assets/icons/coin.svg";

// Импортируем Skeleton из react-loading-skeleton
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

// Импортируем хук для доступа к пользователю из стора
import { useUserStore } from "../../store/useUserStore";

interface LeaderboardUser {
  rank: number;
  username: string;
  coins: number;
  isCurrentUser: boolean;
}

interface CurrentUser {
  rank: number;
  username: string;
  coins: number;
  isTop50: boolean;
}

interface ApiResponse {
  top50: LeaderboardUser[];
  currentUser: CurrentUser;
}

const LeaderBoardPage: React.FC = React.memo(() => {
  // Получаем текущего пользователя из стора
  const { user, isLoading: userLoading } = useUserStore();
  const userId = user?.id;

  const [top50, setTop50] = useState<LeaderboardUser[]>([]);
  const [currentUser, setCurrentUser] = useState<CurrentUser | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  // Функция для получения данных лидерборда с мемоизацией
  const fetchLeaderboard = useCallback(
    async (source: CancelTokenSource) => {
      if (!userId) {
        setError("Пользователь не авторизован.");
        setLoading(false);
        return;
      }

      setLoading(true);
      setError(null);
      try {
        const response = await axios.get<ApiResponse>(
          `https://dev.simatap.ru/api/users/leaders`,
          {
            params: { userId },
            cancelToken: source.token,
          }
        );

        const data = response.data;
        setTop50(data.top50);
        setCurrentUser(data.currentUser);
      } catch (err: any) {
        if (axios.isCancel(err)) {
          console.log("Запрос отменен:", err.message);
        } else {
          console.error(err);
          setError(
            "Не удалось загрузить данные. Пожалуйста, попробуйте позже."
          );
        }
      } finally {
        setLoading(false);
      }
    },
    [userId]
  );

  useEffect(() => {
    const source: CancelTokenSource = axios.CancelToken.source();
    fetchLeaderboard(source);

    return () => {
      source.cancel("Запрос отменен пользователем.");
    };
  }, [fetchLeaderboard]);

  // Мемоизация проверок наличия медалей
  const hasFirstPlace = useMemo(() => top50[0], [top50]);
  const hasSecondPlace = useMemo(() => top50[1], [top50]);
  const hasThirdPlace = useMemo(() => top50[2], [top50]);

  // Мемоизация списка топ-50 пользователей
  const leaderboardList = useMemo(() => {
    return top50.map((user, index) => {
      const isTop3 = user.rank <= 3;
      const isCurrentUser = user.isCurrentUser;
      const isLast = index === top50.length - 1; // Проверяем, последний ли элемент

      return (
        <div
          key={user.rank}
          className={`${styles.leaderboardItem} ${isTop3 ? styles.top3 : ""} ${
            isCurrentUser ? styles.highlightedUser : ""
          } ${isLast ? styles.lastItem : ""}`}
        >
          <span className={styles.rank}>#{user.rank}</span>
          <span className={styles.name}>{user.username}</span>
          <div className={styles.rewardAmount}>
            <span className={styles.score}>
              <CoinIcon className={styles.coin_icon} />
              {Math.floor(user.coins).toLocaleString()}
            </span>
          </div>
        </div>
      );
    });
  }, [top50]);

  // Мемоизация отображения текущего пользователя, если он не в топ-50
  const currentUserDisplay = useMemo(() => {
    if (currentUser && !currentUser.isTop50) {
      return (
        <div className={styles.currentUser}>
          <span className={styles.rank}>#{currentUser.rank}</span>
          <span className={styles.name}>{currentUser.username}</span>
          <div className={styles.rewardAmount}>
            <span className={styles.score_amount}>
              <CoinIcon className={styles.coin_icon} />

              {Math.floor(currentUser.coins).toLocaleString()}
            </span>
          </div>
        </div>
      );
    }
    return null;
  }, [currentUser]);

  // Компонент для отображения наград (первые три места)
  const Rewards: React.FC = React.memo(() => (
    <div className={styles.rewards}>
      {/* Второе место */}
      {hasSecondPlace && (
        <div className={`${styles.reward} ${styles.second_place}`}>
          <img src={secondPlaceIcon} alt="Второе место" />
          <div className={styles.rewardAmount}>
            <CoinIcon className={styles.rubleIcon} />
            <p className={styles.reward_count}>7 M</p>
          </div>
        </div>
      )}

      {/* Первое место */}
      {hasFirstPlace && (
        <div className={`${styles.reward} ${styles.first_place}`}>
          <img src={firstPlaceIcon} alt="Первое место" />
          <div className={styles.rewardAmount}>
            <CoinIcon className={styles.rubleIcon} />
            <p className={styles.reward_count}>13 M</p>
          </div>
        </div>
      )}

      {/* Третье место */}
      {hasThirdPlace && (
        <div className={`${styles.reward} ${styles.third_place}`}>
          <img src={thirdPlaceIcon} alt="Третье место" />
          <div className={styles.rewardAmount}>
            <CoinIcon className={styles.rubleIcon} />
            <p className={styles.reward_count}>5 M</p>
          </div>
        </div>
      )}
    </div>
  ));

  // Компонент для отображения списка пользователей
  const LeaderboardList: React.FC = React.memo(() => (
    <div className={styles.leaderboardList}>
      {leaderboardList}
      {currentUserDisplay}
    </div>
  ));

  // Компонент для отображения состояния загрузки
  const LoadingState: React.FC = React.memo(() => (
    <div className={styles.leaderboard}>
      <main className={styles.mainContent}>
        <h2 className={styles.heading}>
          <Skeleton width={200} />
        </h2>
        <p className={styles.subtitle}>
          <Skeleton width={400} />
        </p>

        <Rewards />

        <div className={styles.leaderboardList}>
          {[...Array(5)].map((_, index) => (
            <div
              key={index}
              className={`${styles.leaderboardItem} ${
                index < 3 ? styles.top3 : ""
              }`}
            >
              <span className={styles.rank}>
                <Skeleton width={30} />
              </span>
              <span className={styles.name}>
                <Skeleton width={150} />
              </span>
              <div className={styles.rewardAmount}>
                <Skeleton circle height={30} width={30} />
                <Skeleton width={80} />
              </div>
            </div>
          ))}
        </div>
      </main>
    </div>
  ));

  // Компонент для отображения ошибки
  const ErrorState: React.FC = React.memo(() => (
    <div className={styles.leaderboard}>
      <main className={styles.mainContent}>
        <h2 className={styles.heading}>Рейтинг</h2>
        <p className={styles.subtitle}>
          Занимай первые места в рейтинге за неделю и получай денежное
          вознаграждение
        </p>
        <p className={styles.error}>{error}</p>
      </main>
    </div>
  ));

  // Основной компонент отображения лидерборда
  const Leaderboard: React.FC = React.memo(() => (
    <div className={styles.leaderboard}>
      <main className={styles.mainContent}>
        <h2 className={styles.heading}>Рейтинг</h2>
        <p className={styles.subtitle}>
          Занимай первые места в рейтинге за неделю и получай денежное
          вознаграждение
        </p>

        <Rewards />

        <LeaderboardList />
      </main>
    </div>
  ));

  // Рендеринг различных состояний
  return (
    <>
      {loading || userLoading ? (
        <LoadingState />
      ) : error ? (
        <ErrorState />
      ) : (
        <Leaderboard />
      )}
    </>
  );
});

export default LeaderBoardPage;
