// src/components/UI/DailyRewardModal/DailyRewardModal.tsx

import React, { useState, useEffect } from "react";
import axios from "axios";
import styles from "./DailyRewardModal.module.scss";
import dailyCardsImg from "../../../assets/icons/daily-cards.png";
import { useUserStore } from "../../../store/useUserStore";
import { DailyCard, Card } from "../../../types/DailyCard";
import { ReactComponent as RewardCard } from "../../../assets/icons/reward-card.svg";
import { ReactComponent as DisabledRewardCard } from "../../../assets/icons/disabled-reward-card.svg";
import useModalStore from "../../../store/useModalStore";
import RewardCollectionModal from "./RewardCollectionModal/RewardCollectionModal";

// Объявляем типы для require.context
declare const require: {
  context(
    path: string,
    deep?: boolean,
    filter?: RegExp
  ): {
    keys: () => string[];
    (id: string): string;
  };
};

// Импортируем все изображения из директории 'assets/images/cards'
const cardImagesContext = require.context(
  "../../../assets/images/cards",
  false,
  /\.(png|jpe?g|webp)$/
);

// Функция для получения изображения по имени файла
const getCardImage = (imageName: string): string | undefined => {
  try {
    return cardImagesContext(`./${imageName}`);
  } catch (error) {
    console.error(`Изображение ${imageName} не найдено.`);
    return undefined;
  }
};

// Helper function to check if two dates are the same day (используем UTC)
const isSameDay = (d1: Date, d2: Date): boolean => {
  return (
    d1.getUTCFullYear() === d2.getUTCFullYear() &&
    d1.getUTCMonth() === d2.getUTCMonth() &&
    d1.getUTCDate() === d2.getUTCDate()
  );
};

interface DailyRewardModalProps {
  dailyCards: DailyCard[];
  setDailyCards: React.Dispatch<React.SetStateAction<DailyCard[]>>;
  refreshDailyCards: () => Promise<void>; // Новый пропс для обновления карт
  closeModal: () => void;
}

const DailyRewardModal: React.FC<DailyRewardModalProps> = ({
  dailyCards,
  setDailyCards,
  refreshDailyCards, // Получаем новый пропс
  closeModal,
}) => {
  const [cards, setCards] = useState<DailyCard[]>(dailyCards);
  const [error, setError] = useState<string | null>(null);
  const [currentCycleDay, setCurrentCycleDay] = useState<number>(1);

  const { user } = useUserStore();
  const userId = user?.id;

  const { openModal, closeModal: closeModalFromStore } = useModalStore();

  // Синхронизация состояния с пропсами
  useEffect(() => {
    setCards(dailyCards);
  }, [dailyCards]);

  useEffect(() => {
    if (!userId) return;

    // Найти последнюю открытую карту
    const openedCards = cards.filter((c) => c.opened && c.timestamp);
    let latestOpenedCard: DailyCard | null = null;

    if (openedCards.length > 0) {
      // Сортируем по timestamp в порядке убывания
      openedCards.sort(
        (a, b) =>
          new Date(b.timestamp!).getTime() - new Date(a.timestamp!).getTime()
      );
      latestOpenedCard = openedCards[0];
    }

    let cycleDay: number;

    if (latestOpenedCard) {
      const openedDate = new Date(latestOpenedCard.timestamp!);
      const today = new Date();

      console.log("Opened Date:", openedDate.toISOString());
      console.log("Today:", today.toISOString());
      console.log("Is Same Day:", isSameDay(openedDate, today));

      if (isSameDay(openedDate, today)) {
        cycleDay = latestOpenedCard.day;
      } else {
        cycleDay = latestOpenedCard.day < 13 ? latestOpenedCard.day + 1 : 1;
      }
    } else {
      cycleDay = 1;
    }

    console.log("Cycle Day:", cycleDay);

    setCurrentCycleDay(cycleDay);

    // Устанавливаем таймер на обновление дня в цикле в полночь
    const now = new Date();
    const msUntilMidnight =
      new Date(
        Date.UTC(
          now.getUTCFullYear(),
          now.getUTCMonth(),
          now.getUTCDate() + 1,
          0,
          0,
          0,
          0
        )
      ).getTime() - now.getTime();

    const timer = setTimeout(() => {
      setCurrentCycleDay((prevDay) => (prevDay < 13 ? prevDay + 1 : 1));
    }, msUntilMidnight);

    return () => clearTimeout(timer);
  }, [userId, cards]);

  // Функция для определения текущего дня для выделения
  const getHighlightCardDay = (): number => {
    return currentCycleDay;
  };

  const highlightCardDay = getHighlightCardDay();

  // Обработка клика по карте
  const handleCardClick = async (card: DailyCard) => {
    try {
      if (card.opened) {
        if (card.card) {
          openModal(
            <RewardCollectionModal
              card={card.card}
              dayNumber={card.day}
              onComplete={() => handleRewardComplete(card.card!.cardId)}
              isNewlyOpened={false}
              isCollected={card.collected}
              closeModal={() => closeModalFromStore()}
              isInitiallyFlipped={true} // Уже открыта, показываем сразу лицевой стороной
            />,
            "linear-gradient(180deg, #2D3236 0%, #000000 100%)"
          );
        }
      } else {
        // Проверяем, что это выделенная карта
        if (card.day !== highlightCardDay) {
          return;
        }

        const response = await axios.post<{ card: Card }>(
          `${process.env.REACT_APP_API_URL}api/daily-cards/open`,
          {},
          {
            params: { userId },
          }
        );

        const openedCard = response.data.card;

        const updatedCards = cards.map((c) =>
          c.day === card.day
            ? {
                ...c,
                opened: true,
                card: openedCard,
                collected: false,
                timestamp: new Date().toISOString(), // Устанавливаем текущий timestamp
              }
            : c
        );
        setCards(updatedCards);
        setDailyCards(updatedCards); // Обновляем состояние в родительском компоненте

        // 1. Вызовите refreshDailyCards для обновления списка с сервера
        await refreshDailyCards();

        openModal(
          <RewardCollectionModal
            card={openedCard}
            dayNumber={card.day}
            onComplete={() => handleRewardComplete(openedCard.cardId)}
            isNewlyOpened={true}
            isCollected={false}
            closeModal={() => closeModalFromStore()}
            isInitiallyFlipped={false} // Новая карта, показываем перевёрнутой
          />,
          "linear-gradient(180deg, #2D3236 0%, #000000 100%)"
        );
      }
    } catch (error) {
      console.error("Ошибка при открытии карточки:", error);
      setError("Не удалось открыть награду.");
    }
  };

  // Обработка завершения награды
  const handleRewardComplete = (cardId: number) => {
    const updatedCards = cards.map((c) =>
      c.card?.cardId === cardId ? { ...c, collected: true } : c
    );
    setCards(updatedCards);
    setDailyCards(updatedCards); // Обновляем состояние в родительском компоненте
  };

  if (!userId) {
    console.error("User ID not found.");
    return null;
  }

  return (
    <div className={styles.modalContent}>
      <div className={styles.header}>
        <img
          src={dailyCardsImg}
          alt="Daily Cards"
          className={styles.headerImage}
        />
        <h3 className={styles.subtitle}>Карта дня</h3>
        <p className={styles.description}>
          Заходи каждый день, получай вознаграждение и совет на день!
        </p>
      </div>

      <div className={styles.cardsContainer}>
        {cards.map((card) => {
          const isCurrent = card.day === highlightCardDay;
          return (
            <DailyRewardCard
              key={card.day}
              day={card.day}
              card={card}
              isCurrent={isCurrent}
              onClick={handleCardClick}
              getCardImage={getCardImage}
            />
          );
        })}
      </div>

      {error && <div className={styles.error}>{error}</div>}
    </div>
  );
};

export default DailyRewardModal;

// Компонент DailyRewardCard

interface DailyRewardCardProps {
  day: number;
  card: DailyCard;
  isCurrent: boolean;
  onClick: (card: DailyCard) => void;
  getCardImage: (imageName: string) => string | undefined;
}

const DailyRewardCard: React.FC<DailyRewardCardProps> = ({
  day,
  card,
  isCurrent,
  onClick,
  getCardImage,
}) => {
  const handleClick = () => {
    if (isCurrent || card.opened) {
      onClick(card);
    }
  };

  return (
    <div
      className={`${styles.card} ${isCurrent ? styles.currentDay : ""} ${
        card.opened ? styles.opened : ""
      } ${card.opened && card.collected ? styles.completed : ""}`}
      onClick={handleClick}
      style={{
        cursor: isCurrent || card.opened ? "pointer" : "default",
      }}
    >
      <div className={styles.dayLabel}>День {day}</div>
      {card.opened && card.card ? (
        // Если карточка открыта и есть объект card, отображаем изображение карточки
        <img
          src={getCardImage(card.card.image_url)}
          alt={`Карточка День ${day}`}
          className={styles.cardImage}
        />
      ) : isCurrent ? (
        <RewardCard className={styles.cardImage} />
      ) : (
        // Для неоткрытых карточек без градиента используем компонент DisabledRewardCard
        <DisabledRewardCard className={styles.cardImage} />
      )}
    </div>
  );
};
