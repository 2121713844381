// src/components/UI/DailyRewardModal/RewardCollectionModal/RewardCollectionModal.tsx

import React, { useState } from "react";
import styles from "./RewardCollectionModal.module.scss";
import useCoinStore from "../../../../store/useCoinStore";
import { ReactComponent as CoinIcon } from "../../../../assets/icons/coin.svg";
import rewardCard from "../../../../assets/icons/big-reward-card.svg";
import { Card } from "../../../../types/DailyCard";

// Объявляем типы для require.context
declare const require: {
  context(
    path: string,
    deep?: boolean,
    filter?: RegExp
  ): {
    keys: () => string[];
    (id: string): string;
  };
};

// Импортируем контекст изображений
const cardImagesContext = require.context(
  "../../../../assets/images/cards",
  false,
  /\.(png|jpe?g|webp)$/
);

// Функция для получения изображения по имени файла
const getCardImage = (imageName: string): string | null => {
  try {
    return cardImagesContext(`./${imageName}`);
  } catch (error) {
    console.error(`Изображение ${imageName} не найдено.`);
    return null; // Или путь к изображению-заглушке
  }
};

interface RewardCollectionModalProps {
  card: Card;
  dayNumber: number;
  onComplete: () => void;
  isNewlyOpened: boolean;
  isCollected: boolean;
  closeModal: () => void;
  isInitiallyFlipped?: boolean; // Новый пропс
  refreshDailyCards?: () => Promise<void>; // Новый пропс (опционально)
}

const RewardCollectionModal: React.FC<RewardCollectionModalProps> = ({
  card,
  dayNumber,
  onComplete,
  isNewlyOpened,
  isCollected,
  closeModal,
  isInitiallyFlipped = false,
  refreshDailyCards, // Получаем новый пропс
}) => {
  const [isFlipped, setIsFlipped] = useState<boolean>(isInitiallyFlipped);
  const { incrementCoins } = useCoinStore();

  // Функция для переключения состояния переворота
  const handleFlip = () => {
    if (!isInitiallyFlipped) {
      // Разрешаем переворот только если карта не изначально перевёрнута
      setIsFlipped((prev) => !prev);
    }
  };

  // Обработка сбора награды
  const handleCollect = async () => {
    if (isNewlyOpened && !isCollected) {
      incrementCoins(card.reward_amount);
    }
    onComplete();
    if (refreshDailyCards) {
      await refreshDailyCards(); // Обновляем ежедневные карты после сбора
    }
    closeModal(); // Закрываем только текущий модал
  };

  return (
    <div className={styles.modalContent}>
      <h3 className={styles.title}>День №{dayNumber}</h3>

      {/* Условный рендеринг текста */}
      {isFlipped && <p className={styles.message}>{card.message}</p>}

      <div
        className={`${styles.cardContainer} ${isFlipped ? styles.flipped : ""}`}
        onClick={!isFlipped ? handleFlip : undefined} // Добавляем обработчик клика только если карта не перевёрнута
        style={{
          cursor:
            !isFlipped && (isNewlyOpened || !isCollected)
              ? "pointer"
              : "default",
        }} // Изменяем курсор, если возможно переворот
      >
        <div className={styles.card}>
          <div className={`${styles.cardFace} ${styles.front}`}>
            <img
              src={rewardCard}
              className={styles.rewardIcon}
              alt="Reward Card Front"
            />
          </div>
          <div className={`${styles.cardFace} ${styles.back}`}>
            <img
              src={getCardImage(card.image_url) || ""}
              alt={`День №${dayNumber}`}
              className={styles.cardImage}
            />
            {/* Удаляем текст из обратной стороны карты */}
            {/* <p className={styles.message}>{card.message}</p> */}
            <div className={styles.rewardAmount}>
              <CoinIcon />
              <span>+{card.reward_amount.toLocaleString()}</span>
            </div>
          </div>
        </div>
      </div>
      {/*{isFlipped && (
        <button
          className={`${styles.collectButton} ${
            isCollected ? styles.completed : ""
          }`}
          onClick={handleCollect}
        >
          {isCollected ? "Закрыть" : "Забрать"}
        </button>
      )}*/}
    </div>
  );
};

export default RewardCollectionModal;
