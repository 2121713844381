// src/pages/QuestsPage.tsx

import React, { useState, useCallback, useEffect } from "react";
import axios from "axios";
import styles from "./QuestsPage.module.scss";
import magImg from "../../assets/images/mag.webp";
import { ReactComponent as CoinIcon } from "../../assets/icons/coin.svg";
import { ReactComponent as RightArrow } from "../../assets/icons/right-arrow.svg";
import { ReactComponent as DoneIcon } from "../../assets/icons/done-icon.svg";
import useModalStore from "../../store/useModalStore";
import DailyRewardModal from "../../components/UI/DailyRewardModal/DailyRewardModal";
import QuestModalContent from "../../components/QuestModalContent/QuestModalContent";
import { useUserStore } from "../../store/useUserStore";
import { Quest } from "../../types/Quest";
import { DailyCard } from "../../types/DailyCard";

const QuestsPage: React.FC = () => {
  const [quests, setQuests] = useState<Quest[]>([]);
  const [dailyCards, setDailyCards] = useState<DailyCard[]>([]);
  const [loadingDailyCards, setLoadingDailyCards] = useState<boolean>(true);
  const [errorDailyCards, setErrorDailyCards] = useState<string | null>(null);
  //const [currentTime, setCurrentTime] = useState<number>(Date.now());

  const { openModal, closeModal, setSelectedQuest } = useModalStore();
  const { user } = useUserStore();
  const userId = user?.id;

  const fetchDailyCards = useCallback(async () => {
    if (!userId) {
      console.error("User ID not found.");
      setLoadingDailyCards(false);
      return;
    }

    setLoadingDailyCards(true);
    setErrorDailyCards(null);

    try {
      const response = await axios.get<{ cards: DailyCard[] }>(
        `${process.env.REACT_APP_API_URL}api/daily-cards`,
        {
          params: { userId },
        }
      );

      const cardsWithCollected = response.data.cards
        .map((card) => ({
          ...card,
          collected: card.opened, // Используем свойство 'opened' из API
        }))
        .sort((a, b) => a.day - b.day); // Сортировка по дню

      setDailyCards(cardsWithCollected);
    } catch (error) {
      console.error("Error fetching daily cards:", error);
      setErrorDailyCards("Не удалось загрузить ежедневные награды.");
    } finally {
      setLoadingDailyCards(false);
    }
  }, [userId]);

  // Использование fetchDailyCards при инициализации
  useEffect(() => {
    fetchDailyCards();
  }, [fetchDailyCards]);

  // Функция для обновления списка заданий
  const updateQuests = useCallback(async () => {
    if (!userId) return;

    try {
      const response = await axios.get<{ tasks: Quest[] }>(
        `${process.env.REACT_APP_API_URL}api/tasks`,
        {
          params: { userId },
        }
      );
      if (Array.isArray(response.data.tasks)) {
        setQuests(response.data.tasks);

        // Получаем текущий selectedQuest из хранилища
        const currentSelectedQuest = useModalStore.getState().selectedQuest;

        if (currentSelectedQuest) {
          const updatedQuest = response.data.tasks.find(
            (q) => q.taskId === currentSelectedQuest.taskId
          );
          if (updatedQuest) {
            setSelectedQuest(updatedQuest);
          } else {
            setSelectedQuest(null); // Если задание больше не существует
          }
        }
      } else {
        console.error("Unexpected response format:", response.data);
      }
    } catch (error) {
      console.error("Error fetching quests:", error);
    }
  }, [userId, setSelectedQuest]);

  useEffect(() => {
    updateQuests();
  }, [updateQuests]);

  // Обработка завершения задания
  const handleCompleteQuest = useCallback((taskId: number) => {
    setQuests((prevQuests) =>
      prevQuests.map((quest) =>
        quest.taskId === taskId ? { ...quest, status: "completed" } : quest
      )
    );
  }, []);

  // Обработка клика по заданию
  const handleQuestClick = useCallback(
    (quest: Quest) => {
      if (quest.status === "completed") return;

      setSelectedQuest(quest); // Устанавливаем выбранное задание в глобальное состояние

      const modalId = openModal(
        <QuestModalContent
          onComplete={(taskId: number) => handleCompleteQuest(taskId)}
          onClose={() => closeModal(modalId)}
          updateQuests={updateQuests} // Передаём функцию обновления заданий
        />,
        "linear-gradient(180deg, #2D3236 0%, #000000 100%)"
      );
    },
    [openModal, closeModal, handleCompleteQuest, updateQuests, setSelectedQuest]
  );

  // Обработка клика по ежедневной награде
  const handleDailyRewardClick = useCallback(() => {
    const modalId = openModal(
      <DailyRewardModal
        dailyCards={dailyCards}
        setDailyCards={setDailyCards}
        refreshDailyCards={fetchDailyCards} // Передаём функцию обновления ежедневных карт
        closeModal={() => closeModal(modalId)}
      />,
      "linear-gradient(180deg, #2D3236 0%, #000000 100%)"
    );
  }, [openModal, closeModal, dailyCards, setDailyCards, fetchDailyCards]);

  // Таймер для заданий типа "timer"
  useEffect(() => {
    // Обновление состояния каждые секунды
    const interval = setInterval(() => {
      setQuests((prevQuests) =>
        prevQuests.map((quest) => {
          if (
            quest.type === "timer" &&
            quest.timeRemaining &&
            quest.timeRemaining > 0
          ) {
            const newTime = quest.timeRemaining - 1;
            return { ...quest, timeRemaining: newTime > 0 ? newTime : 0 };
          }
          return quest;
        })
      );
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  // Функция для форматирования чисел
  const formatNumber = (num: number): string => {
    return new Intl.NumberFormat("ru-RU").format(num);
  };

  // Найти первый несобранный день
  const firstUncollectedIndex = dailyCards.findIndex((card) => !card.collected);

  return (
    <div className={styles.questsPage}>
      <h1 className={styles.title}>Задания</h1>
      <p className={styles.subtitle}>Выполняй задания и получай награду!</p>

      {/* Блок ежедневной награды */}
      <div
        className={styles.dailyReward}
        onClick={handleDailyRewardClick}
        style={{ cursor: "pointer" }}
      >
        <div className={styles.dailyReward_top}>
          <div className={styles.dailyRewardContent}>
            <img
              src={magImg}
              alt="Ежедневная награда"
              className={styles.rewardImage}
            />

            <div className={styles.rewardDetails}>
              <h2 className={styles.rewardTitle}>Ежедневная награда</h2>

              <div className={styles.rewardAmountWrapper}>
                <CoinIcon className={styles.coinIcon} />
                {/* Предполагается, что ежедневная награда также динамическая */}
                <p className={styles.rewardAmount}>+{formatNumber(20000)}</p>
              </div>
            </div>
          </div>

          <RightArrow />
        </div>

        {/* Индикатор прогресса */}
        <div className={styles.progressBar}>
          {loadingDailyCards ? (
            <div className={styles.progressLoading}>Загрузка...</div>
          ) : errorDailyCards ? (
            <div className={styles.progressError}>{errorDailyCards}</div>
          ) : (
            dailyCards.map((card, index) => (
              <div
                key={card.day}
                className={`${styles.progressItem} ${
                  card.collected ? styles.filled : ""
                } ${
                  index === firstUncollectedIndex ? styles.gradientBorder : ""
                }`}
              />
            ))
          )}
        </div>
      </div>

      {/* Список заданий */}
      <div className={styles.questsList}>
        {quests.length === 0 ? (
          <p className={styles.noQuestsMessage}>Нет доступных заданий.</p>
        ) : (
          quests.map((quest) => (
            <div
              key={quest.taskId}
              className={`${styles.questCard} ${
                quest.status === "completed"
                  ? styles.questCompleted
                  : styles.questIncomplete
              }`}
              onClick={() => handleQuestClick(quest)}
              style={{
                cursor: quest.status === "completed" ? "default" : "pointer",
              }}
            >
              <div className={styles.questCard_top}>
                <div className={styles.questDetails}>
                  <div>
                    <h3 className={styles.questTitle}>{quest.title}</h3>
                    <div className={styles.rewardAmountWrapper}>
                      <CoinIcon className={styles.coinIcon} />
                      {/* Форматирование награды */}
                      <p className={styles.rewardAmount}>
                        {formatNumber(quest.reward)}
                      </p>
                    </div>
                  </div>

                  {quest.status === "completed" ? (
                    <DoneIcon className={styles.doneIcon} />
                  ) : (
                    <RightArrow className={styles.arrowRight} />
                  )}
                </div>
              </div>
            </div>
          ))
        )}
      </div>
    </div>
  );
};

export default React.memo(QuestsPage);
