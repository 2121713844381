// src/components/FooterNav/FooterNav.tsx

import React from "react";
import { NavLink } from "react-router-dom";
import classNames from "classnames"; // Импортируем classnames
import { ReactComponent as HomeIcon } from "../../assets/icons/home.svg";
import { ReactComponent as FriendsIcon } from "../../assets/icons/friends.svg";
import { ReactComponent as TaskListIcon } from "../../assets/icons/task-list.svg";
import { ReactComponent as LeaderboardIcon } from "../../assets/icons/leaderboard.svg";
import { ReactComponent as SimaShopIcon } from "../../assets/icons/coin.svg";
import "./FooterNav.scss";
import { triggerHapticFeedback } from "../../utils/haptic"; // Импортируем функцию

interface NavItem {
  to: string;
  label: string;
  Icon: React.FC<React.SVGProps<SVGSVGElement>>;
  ariaLabel: string;
  additionalClassName?: string; // Переименовали с activeClassName
}

const navItems: NavItem[] = [
  {
    to: "/",
    label: "Главная",
    Icon: HomeIcon,
    ariaLabel: "Главная",
  },
  {
    to: "/friendslist",
    label: "Друзья",
    Icon: FriendsIcon,
    ariaLabel: "Друзья",
  },
  {
    to: "/shop",
    label: "Магазин",
    Icon: SimaShopIcon,
    ariaLabel: "Магазин",
    additionalClassName: "shop-link",
  },
  {
    to: "/leaderboard",
    label: "Лидеры",
    Icon: LeaderboardIcon,
    ariaLabel: "Лидеры",
    additionalClassName: "leaderboard-link",
  },
  {
    to: "/quests",
    label: "Задания",
    Icon: TaskListIcon,
    ariaLabel: "Задания",
  },
];

const FooterNav: React.FC = React.memo(() => {
  // Обработчик клика, вызывающий вибрацию
  const handleNavClick = () => {
    triggerHapticFeedback("soft");
  };

  return (
    <nav className="footer">
      <ul className="footer_menu">
        {navItems.map(({ to, label, Icon, ariaLabel, additionalClassName }) => (
          <li className="footer_menu__item" key={to}>
            <NavLink
              to={to}
              end // Добавляем end для точного совпадения маршрута
              className={({ isActive }) =>
                classNames("nav-link", additionalClassName, {
                  active: isActive,
                })
              }
              aria-label={ariaLabel}
              onClick={handleNavClick} // Добавляем обработчик события
            >
              <Icon
                className={classNames("nav-icon", {
                  "shop-icon": additionalClassName === "shop-link",
                  "leaderboard-icon":
                    additionalClassName === "leaderboard-link",
                })}
              />
              <span className="nav-label">{label}</span>
            </NavLink>
          </li>
        ))}
      </ul>
    </nav>
  );
});

export default FooterNav;
