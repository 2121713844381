// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.passive-income-text {
  font-size: 18px;
  font-weight: 700;
  background: linear-gradient(140.39deg, #deb565 10.72%, #edc871 45.29%, #ffffa8 109.99%);
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  -webkit-text-fill-color: transparent;
}`, "",{"version":3,"sources":["webpack://./src/components/UI/PassiveIncomeDisplay/PassiveIncomeDisplay.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,gBAAA;EACA,uFAAA;EAMA,qBAAA;EACA,6BAAA;EACA,kBAAA;EACA,oCAAA;AAJF","sourcesContent":[".passive-income-text {\n  font-size: 18px;\n  font-weight: 700;\n  background: linear-gradient(\n    140.39deg,\n    #deb565 10.72%,\n    #edc871 45.29%,\n    #ffffa8 109.99%\n  );\n  background-clip: text;\n  -webkit-background-clip: text;\n  color: transparent;\n  -webkit-text-fill-color: transparent;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
