// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* components/TapIcons/TapIcons.scss */
.icon {
  position: absolute;
  will-change: transform, opacity;
  animation: flyIcon 0.7s forwards ease-out;
}

.icon img {
  width: 20px;
  height: 20px;
}

@keyframes flyIcon {
  from {
    opacity: 1;
    transform: translate(-50%, -50%) translate(0, 0) scale(1) rotate(0deg);
  }
  to {
    opacity: 0;
    transform: translate(-50%, -50%) translate(var(--move-x), var(--move-y)) scale(0.5) rotate(720deg);
  }
}`, "",{"version":3,"sources":["webpack://./src/components/TapIcons/TapIcons.scss"],"names":[],"mappings":"AAAA,sCAAA;AACA;EACE,kBAAA;EACA,+BAAA;EACA,yCAAA;AACF;;AAEA;EACE,WAAA;EACA,YAAA;AACF;;AAEA;EACE;IACE,UAAA;IACA,sEAAA;EACF;EACA;IACE,UAAA;IACA,kGAAA;EACF;AACF","sourcesContent":["/* components/TapIcons/TapIcons.scss */\n.icon {\n  position: absolute;\n  will-change: transform, opacity;\n  animation: flyIcon 0.7s forwards ease-out;\n}\n\n.icon img {\n  width: 20px;\n  height: 20px;\n}\n\n@keyframes flyIcon {\n  from {\n    opacity: 1;\n    transform: translate(-50%, -50%) translate(0, 0) scale(1) rotate(0deg);\n  }\n  to {\n    opacity: 0;\n    transform: translate(-50%, -50%) translate(var(--move-x), var(--move-y))\n      scale(0.5) rotate(720deg);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
