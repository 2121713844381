// src/components/UI/Modal/Modal.tsx

import React, {
  useEffect,
  useRef,
  useState,
  ReactNode,
  useCallback,
} from "react";
import ReactDOM from "react-dom";
import "./Modal.scss";
import closeIcon from "../../../assets/icons/close.svg";

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  children: ReactNode;
  backgroundColor?: string;
}

const Modal: React.FC<ModalProps> = React.memo(
  ({ isOpen, onClose, children, backgroundColor }) => {
    const modalRef = useRef<HTMLDivElement>(null);
    const [isClosing, setIsClosing] = useState(false);
    const [animate, setAnimate] = useState(false);

    // Prevent body scroll when modal is open
    useEffect(() => {
      if (isOpen || isClosing) {
        document.body.style.overflow = "hidden";
      } else {
        document.body.style.overflow = "";
      }
      return () => {
        document.body.style.overflow = "";
      };
    }, [isOpen, isClosing]);

    // Handle animation on open
    useEffect(() => {
      if (isOpen) {
        setIsClosing(false);
        setAnimate(false);

        // Trigger animation after a short delay to allow CSS transitions
        const timer = setTimeout(() => {
          setAnimate(true);
        }, 10); // 10ms delay to ensure the class is applied after rendering

        return () => {
          clearTimeout(timer);
        };
      }
    }, [isOpen]);

    const handleClose = useCallback(() => {
      setIsClosing(true);
      setAnimate(false);
      setTimeout(() => {
        setIsClosing(false);
        onClose();
      }, 300); // Duration should match CSS transition
    }, [onClose]);

    // Close modal on ESC key
    useEffect(() => {
      const handleKeyDown = (e: KeyboardEvent) => {
        if (e.key === "Escape" && isOpen) {
          handleClose();
        }
      };
      document.addEventListener("keydown", handleKeyDown);
      return () => {
        document.removeEventListener("keydown", handleKeyDown);
      };
    }, [isOpen, handleClose]);

    if (!isOpen && !isClosing) return null;

    return ReactDOM.createPortal(
      <div
        className={`modal-overlay ${isClosing ? "fade-out" : "fade-in"}`}
        style={{ background: "rgba(0, 0, 0, 0.5)" }}
        onClick={handleClose} // Close when clicking on the overlay
      >
        <div
          className={`modal-content ${
            isClosing
              ? "slide-down fade-out"
              : animate
              ? "slide-up fade-in"
              : ""
          }`}
          style={{ background: backgroundColor || "#1c1c1e" }}
          ref={modalRef}
          onClick={(e) => e.stopPropagation()} // Prevent closing when clicking inside the modal
        >
          <button
            type="button"
            className="close-button"
            onClick={handleClose}
            aria-label="Close modal"
          >
            <img src={closeIcon} alt="Close" className="close-icon" />
          </button>
          {children}
        </div>
      </div>,
      document.body
    );
  }
);

export default Modal;
