// src/App.tsx

import React, {
  useLayoutEffect,
  useEffect,
  useState,
  createContext,
  useCallback,
} from "react";
import { BrowserRouter } from "react-router-dom";
import AppRouter from "./router/AppRouter";
import "./index.css";
import "./App.scss";
import { useUserStore } from "./store/useUserStore";
import useCoinStore from "./store/useCoinStore";
import LoadingScreen from "./components/UI/LoadingScreen/LoadingScreen";
import axios from "axios";

export const CoinEffectContext = createContext({
  triggerCoinEffect: () => {},
});

const App: React.FC = () => {
  const [isInitialized, setIsInitialized] = useState(false);
  const { initializeUser } = useUserStore();
  const { initializeStore } = useCoinStore();

  const triggerCoinEffect = useCallback(() => {
    console.log("Coin effect triggered!");
  }, []);

  // Определяем режим моковых данных
  const isMockMode = process.env.REACT_APP_USE_MOCK_DATA === "true";

  // Используем useLayoutEffect для раннего вызова tg.expand
  useLayoutEffect(() => {
    const tg = (window as any).Telegram?.WebApp;
    if (tg) {
      tg.expand?.();
      tg.disableVerticalSwipes?.();
      tg.isVerticalSwipesEnabled = false;
      tg.setHeaderColor?.("#272727");
      tg.setBackgroundColor?.("#272727");

      if (tg.web_app_setup_swipe_behavior) {
        tg.web_app_setup_swipe_behavior({
          allow_vertical_swipe: false,
        });
      }
    }
  }, []);

  useEffect(() => {
    const initUserAndStore = async () => {
      // Если используем моковые данные, сбрасываем состояние хранилища
      if (isMockMode) {
        useCoinStore.setState({
          coins: 0,
          coinsPerClick: 13,
          passiveIncomeRate: 0,
          energy: 2000,
          maxEnergy: 2000,
          availableBoosters: 6,
          totalBoosters: 6,
          userId: null,
          offlineIncome: 0,
          upgrades: [],
          isPurchasing: null,
        });
      }

      await initializeUser();
      const currentUser = useUserStore.getState().user;

      if (currentUser) {
        initializeStore(currentUser);
      }

      setIsInitialized(true);

      // Обработка реферальных параметров, если это необходимо
      const tg = window.Telegram?.WebApp;
      if (tg?.initDataUnsafe?.start_param) {
        const startParam = tg.initDataUnsafe.start_param;
        const referrerId = startParam.replace("refId", "");
        const referralId = currentUser?.id;

        if (referralId && referrerId && referralId !== referrerId) {
          try {
            const response = await axios.post(
              "https://dev.simatap.ru/api/referrals",
              {},
              {
                params: {
                  referrer_id: referrerId,
                  referral_id: referralId,
                },
                headers: {
                  "Content-Type": "application/json",
                },
              }
            );
            console.log("Реферал успешно зарегистрирован:", response.data);
          } catch (error: any) {
            if (axios.isAxiosError(error) && error.response) {
              const errorMessage = error.response.data?.message;
              if (errorMessage === "Такая запись о реферале уже существует") {
                console.log("Реферал уже существует.");
              } else {
                console.error("Ошибка при регистрации реферала:", errorMessage);
              }
            } else {
              console.error("Ошибка при регистрации реферала:", error);
            }
          }
        }
      }
    };

    initUserAndStore();
  }, [initializeUser, initializeStore, isMockMode]);

  if (!isInitialized) {
    return <LoadingScreen />;
  }

  return (
    <CoinEffectContext.Provider value={{ triggerCoinEffect }}>
      <BrowserRouter basename="/misapat">
        <AppRouter />
      </BrowserRouter>
    </CoinEffectContext.Provider>
  );
};

export default App;
