// src/components/UI/OfflineIncomeModal/OfflineIncomeModal.tsx

import React, { useState } from "react";
import ReactDOM from "react-dom";
import styles from "./OfflineIncomeModal.module.scss";
import { ReactComponent as CoinIcon } from "../../../assets/icons/coin.svg";

interface OfflineIncomeModalProps {
  income: number;
  onClose: () => void;
}

const OfflineIncomeModal: React.FC<OfflineIncomeModalProps> = ({
  income,
  onClose,
}) => {
  const [showAnimation, setShowAnimation] = useState(false);

  const handleClose = () => {
    console.log("Кнопка 'Забрать награду' нажата"); // Отладка

    // Проверяем, доступен ли API Telegram Web Apps
    if (
      window.Telegram &&
      window.Telegram.WebApp &&
      window.Telegram.WebApp.HapticFeedback &&
      typeof window.Telegram.WebApp.HapticFeedback.notificationOccurred ===
        "function"
    ) {
      window.Telegram.WebApp.HapticFeedback.notificationOccurred("success");
    } else {
      // Фолбэк на стандартную вибрацию, если API недоступен
      if (navigator.vibrate) {
        navigator.vibrate(100);
      }
    }

    onClose(); // Закрытие модалки
    setShowAnimation(true); // Показ анимации
    setTimeout(() => {
      setShowAnimation(false); // Скрыть анимацию через 1 секунду
    }, 1000); // 1000 мс = 1 секунда
  };

  return (
    <>
      <div className={styles.offlineIncomeModal}>
        <CoinIcon className={styles.coinIcon} />
        <h2 className={styles.title}>Твой доход составил</h2>
        <p className={styles.message}>
          С момента твоего последнего посещения пассивный доход принёс тебе
        </p>

        <span></span>

        <div className={styles.income_wrapper}>
          <CoinIcon />
          <span className={styles.incomeValue}>
            + {income.toLocaleString()}
          </span>
        </div>
        <button className={styles.closeButton} onClick={handleClose}>
          Забрать награду
        </button>
      </div>
    </>
  );
};

export default OfflineIncomeModal;
