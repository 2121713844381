import React, { useEffect, useState, useCallback, useMemo } from "react";
import axios from "axios";
import styles from "./FriendsInvitePage.module.scss";
import getReferralLink from "../../helpers/getReferralLink";
import tg from "../../utils/tg";
import coinIcon from "../../assets/icons/coin.svg";
import { ReactComponent as CopyIcon } from "../../assets/icons/copy.svg";
// Удаляем импорт toast
// import { toast } from "react-toastify";
import { useUserStore } from "../../store/useUserStore";

interface Friend {
  username: string;
  coins: number;
}

const FriendsInvitePage: React.FC = () => {
  const [friends, setFriends] = useState<Friend[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  const referralLink = useMemo(() => getReferralLink(), []);
  const shareReferralLinkText = "Тапай и зарабатывай!";
  const { user } = useUserStore();
  const userId = user?.id;

  // Функция для вызова вибрации
  const triggerVibration = useCallback(
    (style: "light" | "medium" | "heavy" | "rigid" | "soft") => {
      if (
        window.Telegram &&
        window.Telegram.WebApp &&
        window.Telegram.WebApp.HapticFeedback &&
        typeof window.Telegram.WebApp.HapticFeedback.impactOccurred ===
          "function"
      ) {
        window.Telegram.WebApp.HapticFeedback.impactOccurred(style);
      } else {
        if (navigator.vibrate) {
          navigator.vibrate(50); // Фолбэк вибрации на 50 мс
        }
      }
    },
    []
  );

  const handleShareButtonClick = useCallback(() => {
    if (tg?.openTelegramLink) {
      tg.openTelegramLink(
        `https://t.me/share/url?url=${encodeURIComponent(
          referralLink
        )}&text=${encodeURIComponent(shareReferralLinkText)}`
      );
      triggerVibration("medium"); // Вызываем вибрацию
    } else {
      alert("Не удалось открыть Telegram для совместного использования.");
    }
  }, [referralLink, shareReferralLinkText, triggerVibration]);

  const handleCopyLink = useCallback(() => {
    if (navigator.clipboard && window.isSecureContext) {
      navigator.clipboard
        .writeText(referralLink)
        .then(() => {
          triggerVibration("medium"); // Вызываем вибрацию при успешном копировании
        })
        .catch(() => {
          triggerVibration("medium"); // Вызываем вибрацию даже при ошибке
        });
    } else {
      const textArea = document.createElement("textarea");
      textArea.value = referralLink;
      Object.assign(textArea.style, {
        position: "fixed",
        left: "-999999px",
        top: "-999999px",
      });
      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();

      try {
        const successful = document.execCommand("copy");
        triggerVibration("medium"); // Вызываем вибрацию
      } catch {
        triggerVibration("medium"); // Вызываем вибрацию даже при ошибке
      }

      document.body.removeChild(textArea);
    }
  }, [referralLink, triggerVibration]);

  useEffect(() => {
    if (!userId) {
      setError("Пользователь не найден.");
      setLoading(false);
      return;
    }

    const fetchFriends = async () => {
      try {
        const { data } = await axios.get<Friend[]>(
          `https://dev.simatap.ru/api/referrals/${userId}`
        );
        setFriends(data);
      } catch (err) {
        if (axios.isAxiosError(err) && err.response?.status === 404) {
          setFriends([]);
        } else {
          console.error(err);
          setError("Не удалось загрузить список друзей.");
        }
      } finally {
        setLoading(false);
      }
    };

    fetchFriends();
  }, [userId]);

  const formatCoins = useCallback((coins: number) => {
    return Math.floor(coins).toLocaleString();
  }, []);

  return (
    <div className={styles.friendsInvite}>
      <div className={styles.scrollContainer}>
        {loading ? (
          <p className={styles.loadingText}>Загрузка...</p>
        ) : error ? (
          <p className={styles.errorMessage}>{error}</p>
        ) : (
          <div className={styles.friendsList}>
            <div className={styles.friendsList_wrapper}>
              <header>
                <div className={styles.header}>
                  <h1 className={styles.header_title}>Друзья</h1>
                </div>

                <div className={styles.rewardBlock}>
                  <div className={styles.rewardBlock_top}>
                    <img
                      src={coinIcon}
                      alt="Coin"
                      className={styles.coinIcon}
                    />
                    <p className={styles.rewardBlock_description}>+ 50 000</p>
                  </div>

                  <span className={styles.rewardBlock_description_mini}>
                    тебе и другу!
                  </span>
                </div>
                <p className={styles.friendsList_count}>
                  У тебя {friends.length}{" "}
                  {friends.length === 1 ? "друг" : "друзей"}
                </p>
              </header>

              <div className={styles.friendsListWrapper}>
                {friends.length > 0 ? (
                  <ul>
                    {friends.map((friend, index) => (
                      <li key={index} className={styles.friendItem}>
                        <span className={styles.friendItem_name}>
                          <span className={styles.friendItem_index}>
                            {index + 1}.
                          </span>
                          {friend.username}
                        </span>

                        <div className={styles.friendItem_text}>
                          <img
                            src={coinIcon}
                            alt="Coin"
                            className={styles.friendCoinIcon}
                          />
                          <span className={styles.friendItem_coins}>
                            {formatCoins(friend.coins)}
                          </span>
                        </div>
                      </li>
                    ))}
                  </ul>
                ) : (
                  <p className={styles.noFriendsMessage}>
                    У тебя пока нет друзей. Пригласи друга и получи награду!
                  </p>
                )}
              </div>

              <div className={styles.inviteButtons}>
                <div className={styles.inviteButtons_block}>
                  <button
                    onClick={handleShareButtonClick}
                    className={styles.inviteFriend}
                  >
                    Пригласить друга
                  </button>
                  <button
                    onClick={handleCopyLink}
                    className={styles.copyLink}
                    title="Скопировать реферальную ссылку"
                  >
                    <CopyIcon />
                  </button>
                </div>
                <p className={styles.inviteText}>
                  Приглашай друга и получайте совместную награду!
                </p>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default React.memo(FriendsInvitePage);
